interface MenuProps {
	element: HTMLElement;
	toggle: HTMLElement;
}

class Menu {
	element: HTMLElement;
	toggle: HTMLElement;
	parent: ParentNode | any;
	childDivParent: NodeListOf<HTMLElement>;
	allSvg: NodeListOf<SVGSVGElement>;
	subdivision: string | any;
	subElement: HTMLElement | any;
	titlePlus: HTMLElement | any;
	svg: HTMLElement | any;
	subdivisionTest: HTMLElement | any;

	constructor({ element, toggle }: MenuProps) {
		this.element = element;
		this.toggle = toggle;
		this.parent = this.toggle.parentNode;
		this.childDivParent = this.parent.querySelectorAll('div');
		this.allSvg = document.querySelectorAll('svg')
		// this.subdivision = this.element.children[0].children[0].textContent?.split(' ').join('-').toLowerCase().replace("&", "");
		this.subdivision = this.element.children[0].children[0].textContent?.includes('Finance') ? "finance-and-investment" : this.element.children[0].children[0].textContent?.split(' ').join('-').toLowerCase().replace("&", "");
		this.subdivisionTest = this.element.children[0].children[0].textContent?.split(' ')[0];
		// this.subElement = document.querySelector(`.left-section__plus--${this.subdivisionTest}`);
		this.subElement = this.element.querySelector('p');
		// this.titlePlus = document.querySelector(`.left-section__text--${this.subdivision}`);
		this.titlePlus = this.element;
		// this.svg = this.titlePlus.querySelector(`.middle-section__plus--${this.subdivision} > svg:nth-child(2)`);
		this.svg = document.querySelector(`.left-section__plus--${this.subdivisionTest} > svg:nth-child(2)`);

	}

	displayMiddleSection() {
		this.subElement.addEventListener('click', this.click);
		// this.titlePlus.addEventListener('click', this.click); // click sur les titre du menu pour afficher les sous menu
		openMenu(this.subdivision, this.titlePlus);
	}

	private click = () => {
		if (this.subElement.children[1].classList.contains("activeSvg")) {
			this.subElement.children[1].classList.remove("activeSvg");
			this.toggle.classList.remove('active');

			const children = [...this.toggle.children];
			children.forEach((element: Element, i) => {
				(element as HTMLElement).style.transform = "translateX(-190px)";
			});
		} else {
			for (let i = 0; i < this.childDivParent.length; i++) {
				for (let j = 0; j < this.allSvg.length; j++) {
					if (this.childDivParent[i].classList.contains('active') && this.allSvg[j].classList.contains('activeSvg')) {
						const children = [...this.childDivParent[i].children];
						const childrenActive = [...this.toggle.children];

						this.childDivParent[i].classList.remove('active');
						this.allSvg[j].classList.remove('activeSvg');
						children.forEach((element: Element, i) => {
							(element as HTMLElement).style.transform = "translateX(-190px)";
						});

						this.svg.classList.add('activeSvg');
						this.toggle.classList.add('active');
						childrenActive.forEach((element: Element, i) => {
							(element as HTMLElement).style.transform = "translateX(0px)";
						});
					} else {
						this.toggle.classList.add('active');
						this.svg.classList.add('activeSvg');

						const children = [...this.toggle.children];
						children.forEach((element: Element, i) => {
							(element as HTMLElement).style.transform = "translateX(0px)";
							(element as HTMLElement).classList.add(`fadeActive${i}`);
						});
					}
				}
			}
		}
	}
}

let lang = document.getElementsByTagName('html')[0].getAttribute('lang');

const blockOne = new Menu({
	element: document.querySelector(".left-section__text--" + (lang === "en-US" ? "real-estate" : "immobilier")) as HTMLElement,
	toggle: document.querySelector(".middle-section__content") as HTMLElement
});


const blockTwo = new Menu({
	element: document.querySelector(".left-section__text--" + (lang === "en-US" ? "retail-distribution" : "distribution")) as HTMLElement,
	toggle: document.querySelector(".middle-section__contenttwo") as HTMLElement
});

const blockThree = new Menu({
	// element: document.querySelector(".left-section__text--" + (lang === "en-US" ? "finance-and-investment" : "finance--investissement")) as HTMLElement,
	element: document.querySelector(".left-section__text > li:nth-child(4)") as HTMLElement, // avoid bug 
	toggle: document.querySelector('.middle-section__contentone') as HTMLElement
});

const blockFor = new Menu({
	element: document.querySelector(".left-section__text--" + (lang === "en-US" ? "recruitment" : "recrutement")) as HTMLElement,
	toggle: document.querySelector('.middle-section__contentthree') as HTMLElement
});



function openMenu(subdivision: string, titlePlus: HTMLElement) {
	const burger = document.getElementById('burger') as HTMLElement,
		hrefGroupe = (document.getElementById("menu") as HTMLElement).children[0].children[0].children[1],
		menu = document.getElementById('mainMenu') as HTMLElement,
		divHead = document.getElementById('divHead') as HTMLElement,
		contact = document.getElementById('contactHead') as HTMLElement,
		line1 = document.getElementById("Ligne_1") as HTMLElement,
		line2 = document.getElementById("Ligne_2") as HTMLElement,
		line3 = document.getElementById("Ligne_3") as HTMLElement,
		borderLineHead = ((document.getElementById("header") as HTMLElement).children[0] as HTMLElement),
		body = document.body as HTMLElement;
	let isOpen = false,
		ul = titlePlus.querySelector("ul") as HTMLElement,
		section = [
			document.getElementById("realEstate") as HTMLElement,
			document.getElementById("retailDistribution") as HTMLElement,
			document.getElementById("finance") as HTMLElement,
		];

	burger.addEventListener("click", () => {
		openToggle();
	});

	hrefGroupe.addEventListener("click", () => {
		openToggle();
		section.forEach(element => {
			element.style.transform = "translateX(-150px)";
			element.style.filter = `brightness(0.8)`;
		});
		(document.getElementById("financeRight") as HTMLElement).style.transform = "translateX(100%)";
		(document.getElementById("immoRight") as HTMLElement).style.transform = "translateX(100%)";
		(document.getElementById("distribRight") as HTMLElement).style.transform = "translateX(100%)";
		(document.getElementById('divHead') as HTMLElement).classList.remove('pageMetierActive');
		(document.body as HTMLElement).classList.remove("o-hidden");
	});

	function openToggle() {
		if (isOpen) {
			isOpen = false;
			menu.style.transform = "translateX(100%)";
			divHead.classList.remove('menuOpenActive');
			contact.classList.remove('contactMenu');

			line1.style.transform = "rotate(0deg) translate(0px, 0px)";
			line1.style.strokeWidth = "2px";
			line2.style.transform = "scaleX(1)";
			line3.style.transform = "rotate(0deg) translate(0px, 0px)";
			line3.style.strokeWidth = "2px";

			body.classList.remove("o-hidden");
			borderLineHead.style.borderColor = "#fff";
		} else {
			isOpen = true;
			menu.style.transform = "translateX(0%)";
			divHead.classList.add('menuOpenActive');
			contact.classList.add('contactMenu');

			line1.style.transform = "rotate(45deg) translate(2px, -1px)";
			line1.style.strokeWidth = "3px";
			line2.style.transform = "scaleX(0)";
			line3.style.transform = "rotate(-45deg) translate(-8px, -1px)";
			line3.style.strokeWidth = "3px";

			body.classList.add("o-hidden");
			borderLineHead.style.borderColor = "#262422";
		}
	}

	// Sous menu des page metier pour show les contenu
	Array.from(ul.children).forEach((element, key) => {
		let url = location.hostname === "localhost" ? "/html/tolefi/" : "/";

		if (subdivision === "immobilier" || subdivision === "real-estate") {
			element.addEventListener("click", () => {
				// openToggle();
				burger.click();

				if (key === 2) {
					window.location.href = `${url}projet`;
				} else {
					window.location.href = `${url}#realEstate`;
					(document.getElementById("financeRight") as HTMLElement).style.transform = "translateX(100%)";
					(document.getElementById("distribRight") as HTMLElement).style.transform = "translateX(100%)";

					setTimeout(() => {
						(document.getElementById("immoRight") as HTMLElement).style.transform = "translateX(0%)";
						(document.getElementById('divHead') as HTMLElement).classList.add('pageMetierActive');
						(document.body as HTMLElement).classList.add("o-hidden");

						(document.getElementById("realEstate") as HTMLElement).style.transform = "translateX(-150px)";
						(document.getElementById("realEstate") as HTMLElement).style.filter = `brightness(0.8)`;
						(document.getElementById("retailDistribution") as HTMLElement).style.transform = "translateX(0)";
						(document.getElementById("retailDistribution") as HTMLElement).style.filter = `brightness(1)`;
						(document.getElementById("finance") as HTMLElement).style.transform = "translateX(0)";
						(document.getElementById("finance") as HTMLElement).style.filter = `brightness(1)`;
					}, 500);

					setTimeout(() => {
						let ul = document.querySelector("#immoCard > ul li ul") as HTMLElement;
						(ul.children[key] as HTMLElement).click();
					}, 1000);

					(document.querySelector("#financeCard ul") as HTMLElement).style.transform = "translate(0, 0)";
					(document.querySelector("#distribCard ul") as HTMLElement).style.transform = "translate(0, 0)";
				}
			});
		}
		else if (subdivision === "finance--investissement" || subdivision === "finance-and-investment") {
			element.addEventListener("click", () => {
				// openToggle();
				burger.click();

				window.location.href = `${url}#finance`;
				(document.getElementById("immoRight") as HTMLElement).style.transform = "translateX(100%)";
				(document.getElementById("distribRight") as HTMLElement).style.transform = "translateX(100%)";

				setTimeout(() => {
					(document.getElementById("financeRight") as HTMLElement).style.transform = "translateX(0%)";
					(document.getElementById('divHead') as HTMLElement).classList.add('pageMetierActive');
					(document.body as HTMLElement).classList.add("o-hidden");

					(document.getElementById("finance") as HTMLElement).style.transform = "translateX(-150px)";
					(document.getElementById("finance") as HTMLElement).style.filter = `brightness(0.8)`;
					(document.getElementById("retailDistribution") as HTMLElement).style.transform = "translateX(0)";
					(document.getElementById("retailDistribution") as HTMLElement).style.filter = `brightness(1)`;
					(document.getElementById("realEstate") as HTMLElement).style.transform = "translateX(0)";
					(document.getElementById("realEstate") as HTMLElement).style.filter = `brightness(1)`;
				}, 500);

				setTimeout(() => {
					let ul = document.querySelector("#financeCard > ul li ul") as HTMLElement;
					(ul.children[key] as HTMLElement).click();
				}, 1000);

				(document.querySelector("#immoCard ul") as HTMLElement).style.transform = "translate(0, 0)";
				(document.querySelector("#distribCard ul") as HTMLElement).style.transform = "translate(0, 0)";
			});
		}
		else if (subdivision === "distribution" || subdivision === "retail-distribution") {
			element.addEventListener("click", () => {
				// openToggle();
				burger.click();

				window.location.href = `${url}#retailDistribution`;
				(document.getElementById("financeRight") as HTMLElement).style.transform = "translateX(100%)";
				(document.getElementById("immoRight") as HTMLElement).style.transform = "translateX(100%)";

				setTimeout(() => {
					(document.getElementById("distribRight") as HTMLElement).style.transform = "translateX(0%)";
					(document.getElementById('divHead') as HTMLElement).classList.add('pageMetierActive');
					(document.body as HTMLElement).classList.add("o-hidden");

					(document.getElementById("retailDistribution") as HTMLElement).style.transform = "translateX(-150px)";
					(document.getElementById("retailDistribution") as HTMLElement).style.filter = `brightness(0.8)`;
					(document.getElementById("finance") as HTMLElement).style.transform = "translateX(0)";
					(document.getElementById("finance") as HTMLElement).style.filter = `brightness(1)`;
					(document.getElementById("realEstate") as HTMLElement).style.transform = "translateX(0)";
					(document.getElementById("realEstate") as HTMLElement).style.filter = `brightness(1)`;
				}, 500);

				setTimeout(() => {
					let ul = document.querySelector("#distribCard > ul li ul") as HTMLElement;
					(ul.children[key] as HTMLElement).click();
				}, 1000);

				(document.querySelector("#financeCard ul") as HTMLElement).style.transform = "translate(0, 0)";
				(document.querySelector("#immoCard ul") as HTMLElement).style.transform = "translate(0, 0)";
			});
		}
		else if (subdivision === "recrutement" || subdivision === "recruitment") {
			element.addEventListener("click", () => {
				window.location.href = `${url}recrutement/`;
			});
		}
	});
}

blockOne.displayMiddleSection();
blockTwo.displayMiddleSection();
blockThree.displayMiddleSection();
blockFor.displayMiddleSection();
