interface HeaderProps {
    header: HTMLElement;
}

export class Header {
    header: HTMLElement;
    footer = document.getElementById("footer") as HTMLElement;
    contact = document.getElementById("contactHead") as HTMLElement;
    divHead = document.getElementsByClassName("head") as HTMLCollectionOf<Element>;
    prevScrollpos: Number;
    observer?: IntersectionObserver;
    invertedSections = document.querySelectorAll(".invertedHeader");
    detector = document.querySelector("#divHead .logo svg") as HTMLElement;

    constructor({ header }: HeaderProps) {
        this.header = header;
        this.prevScrollpos = window.pageYOffset;

        this.onScroll();
        this.resetObserver();
    }

    private resetObserver = () => {
        this.observer?.disconnect();

        const { top, height } = this.detector.getBoundingClientRect();

        this.observer = new IntersectionObserver(this.observerCallback, {
            rootMargin: `-${top}px 0px -${window.innerHeight - top - height}px 0px`,
        });

        this.invertedSections.forEach((el) => this.observer?.observe(el));
    };

    private observerCallback = (entries: IntersectionObserverEntry[]) => {
        let inverted = false;
        entries.forEach((entry) => {
            if (entry.isIntersecting) inverted = true;
        });

        if (document.getElementById("main-tolefi-immobilier") || document.getElementById("main-tolefi-finance") || document.getElementById("main-tolefi-distribution")) {
            if (inverted) this.header?.classList.add('invertedBlue'), this.footer?.classList.add('invertedBlue');
            else this.header?.classList.remove('invertedBlue'), this.footer?.classList.remove('invertedBlue');
        } else {
            if (inverted) this.header?.classList.add('inverted');
            else this.header?.classList.remove('inverted');
        }
    };

    private onScroll = () => {
        const home = document.getElementById("home") as HTMLElement;
        let borderLine = document.querySelector("#header .borderLineHead") as HTMLElement;

        if (home) {
            home.addEventListener("scroll", () => {
                let top = (document.getElementById("sectionHome") as HTMLElement).getBoundingClientRect().top;

                if (top < 0) {
                    borderLine.style.transform = "scaleX(0)";
                } else {
                    borderLine.style.transform = "scaleX(1)";
                }
            });
        } else {
            window.addEventListener("scroll", () => {
                const { scrollTop, clientHeight } = document.documentElement;

                if (scrollTop > 0) {
                    borderLine.style.transform = "scaleX(0)";
                } else {
                    borderLine.style.transform = "scaleX(1)";
                }
            });
        }
    };
}
