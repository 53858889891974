const headVideoAnimation = () => {
    const videos = Array.from(document.getElementsByClassName('video-head'));

    videos.map((video: any) => {
        video.addEventListener("mouseover", () => {
            const filter = videos.filter(e => e !== video);
            video.play();
            (filter[0] as HTMLVideoElement).pause();
            (filter[1] as HTMLVideoElement).pause();
            (video as HTMLElement).classList.add('filterGrascale');
        });
        video.addEventListener("mouseout", () => {
            video.pause();
            (video as HTMLElement).classList.remove('filterGrascale');
        });
    });
}

export default headVideoAnimation;
