import { gsap, TweenLite, TweenMax } from 'gsap';

interface SliderPageMetierMobileProps {
	slider: HTMLElement;
}

export class SliderPageMetierMobile {
	dragging = false;
	pointerPos = { x: 0 };
	lastPointerPos = { x: 0 };
	sliderPos = { x: 0 };
	lastSliderPos = { x: 0 };
	totalDist = 0;
	currentSlide = 0;
	momentumTween: TweenLite | TweenMax | null = null;
	distLog: number[] = [];
	velocity = 10;
	duration = 0.8;
	slideWidth = 0;
	inView = true;
	nbrPerSlide = 1;
	y = 0;
	slidingVertically = false; // au cas où on scroll au touch
	observer: IntersectionObserver;
	interval?: number;
	slider: HTMLElement;
	slidesWrapper: HTMLUListElement;
	slides: NodeListOf<HTMLLIElement>;
	links: NodeListOf<HTMLAnchorElement>;

	constructor({ slider }: SliderPageMetierMobileProps) {
		this.slider = slider;
		this.slidesWrapper = this.slider.querySelector('ul')!;
		this.slides = this.slidesWrapper.querySelectorAll(':scope > li');
		this.links = this.slidesWrapper.querySelectorAll('a');

		this.bind();
		this.onResize();

		this.observer = new IntersectionObserver(
			([entry]) => (this.inView = entry.isIntersecting),
			{ threshold: 0 },
		);
		this.observer.observe(this.slider);
	}

	private bind() {
		// Partie Groupe Mobile si on click sur le cta de la page responsive groupe
		if (document.getElementById("grpBack")) {
			(document.getElementById("grpBack") as HTMLElement).addEventListener("click", () => {
				this.setIndex(-1);

				let list = document.querySelectorAll("#grpCard ul li ul li") as NodeListOf<HTMLLIElement>;
				let p = document.querySelectorAll("#grpCard ul li p") as NodeListOf<HTMLLIElement>;
				(document.querySelector("#grpCard ul h3") as HTMLElement).classList.remove("classBefore");

				for (let i = 0; i < p.length; i++) {
					setTimeout(() => {
						p[i].style.transform = "translateX(150%)";
					}, 100 + (50 * i));
				}

				for (let i = 0; i < list.length; i++) {
					setTimeout(() => {
						list[i].style.transform = "translateY(-100%)";
					}, 100 + (50 * i));
				}
			});
		}

		// pour les ul
		if (this.slider.id === "immoCard") {
			(document.querySelectorAll(".clickLiImmo") as NodeListOf<HTMLElement>).forEach((li, key) => {
				(li as HTMLElement).addEventListener("click", () => {
					if (key === 0) {
						this.setSliderPos(2);
					} else if (key === 1) {
						this.setSliderPos(4);
					}
				});
			});
		} else if (this.slider.id === "distribCard") {
			(document.querySelectorAll(".clickLiDistrib") as NodeListOf<HTMLElement>).forEach((li, key) => {
				(li as HTMLElement).addEventListener("click", () => {
					if (key === 0) {
						this.setSliderPos(2);
					} else if (key === 1) {
						this.setSliderPos(5);
					} else if (key === 2) {
						this.setSliderPos(8);
					}
				});
			});
		} else if (this.slider.id === "financeCard") {
			(document.querySelectorAll(".clickLiFinance") as NodeListOf<HTMLElement>).forEach((li, key) => {
				(li as HTMLElement).addEventListener("click", () => {
					if (key === 0) {
						this.setSliderPos(3);
					} else if (key === 1) {
						this.setSliderPos(5);
					}
				});
			});
		}

		this.slider.addEventListener('touchstart', (e) => {
			this.slidingVertically = false;
			this.y = e.touches[0].pageY;
			this.onPointerDown(e.touches[0].pageX);
		});

		this.links.forEach((link) => {
			link.addEventListener('click', (e) => {
				if (this.totalDist !== 0) e.preventDefault();
			});
		});

		window.addEventListener('touchmove', (e) => {
			const diffY = Math.abs(this.y - e.touches[0].pageY);
			if (diffY > 20 && Math.abs(this.totalDist) < 20)
				this.slidingVertically = true;
			if (!this.slidingVertically) this.onPointerMove(e.touches[0].pageX);
			this.y = e.touches[0].pageY;
		});
		window.addEventListener('touchend', () => this.onPointerUp());
		window.addEventListener('resize', () => this.onResize());
	}

	private onResize() {
		this.calcSlideWidth();
		this.setSliderPos(this.currentSlide, false);
		const w = window.innerWidth;
	}

	private calcSlideWidth() {
		this.slideWidth = this.slides[0].clientWidth;
	}

	private onPointerDown(x: number) {
		clearInterval(this.interval);
		this.dragging = true;
		this.pointerPos = { x };
		this.lastPointerPos = { x };
		this.totalDist = 0;
		this.distLog = [];
		this.stopMomentum();
		this.updateSliderPosLoop();
	}

	private onPointerMove(x: number) {
		if (this.dragging) this.pointerPos = { x };
	}

	private onPointerUp() {
		if (!this.dragging) return;

		this.dragging = false;

		const releaseSpeed =
			this.distLog.reduce((a, b) => a + b, 0) / (this.distLog.length || 1);

		let targetX = this.sliderPos.x + releaseSpeed * this.velocity;
		targetX = Math.round(targetX / this.slideWidth) * this.slideWidth;
		let targetSlide = -targetX / this.slideWidth;
		let excess = 0;

		if (targetSlide < 0) {
			excess = targetSlide;
			targetSlide = 0;
		} else if (targetSlide >= this.slides.length - this.nbrPerSlide) {
			excess = targetSlide - (this.slides.length - this.nbrPerSlide);
			targetSlide = this.slides.length - this.nbrPerSlide;
		}
		if (excess !== 0) targetX = -targetSlide * this.slideWidth;

		this.momentumTween = gsap.to(this.sliderPos, {
			duration: this.duration - Math.abs(excess) / 20,
			x: targetX,
			ease: 'power2',
			onUpdate: () => this.updateSliderPos(),
			onComplete: () => this.updateSliderPos(),
		});
	}

	private updateSliderPos() {
		if (this.slider.id !== "grpCard") {
			gsap.set(this.slidesWrapper, { x: this.sliderPos.x, lazy: true });
		}
		this.lastSliderPos.x = this.sliderPos.x;
		this.setIndex(Math.round(-this.sliderPos.x / this.slideWidth));
	}

	private stopMomentum() {
		if (this.momentumTween) {
			this.momentumTween.kill();
			this.momentumTween = null;
		}
	}

	private setSliderPos(index: number, isAnimated = true) {
		this.stopMomentum();
		this.momentumTween = gsap.to(this.sliderPos, {
			duration: isAnimated ? this.duration : 0,
			x: -index * this.slideWidth,
			ease: 'power4',
			onUpdate: () => this.updateSliderPos(),
			onComplete: () => this.updateSliderPos(),
		});
	}

	private updateSliderPosLoop() {
		if (!this.dragging || !this.inView) return;
		this.updateSliderPos();
		const dist = this.pointerPos.x - this.lastPointerPos.x;
		this.lastPointerPos.x = this.pointerPos.x;
		this.totalDist += dist;
		this.sliderPos.x += dist;
		this.distLog.push(dist);
		while (this.distLog.length > 10) this.distLog.splice(0, 1);
		requestAnimationFrame(() => this.updateSliderPosLoop());
	}

	private setIndex(index: number) {
		if (
			index === this.currentSlide ||
			index < 0 ||
			index > this.slides.length - this.nbrPerSlide
		) {
			if (index === -1) {
				if (this.slider.id === "distribCard") {
					(document.getElementById("distribRight") as HTMLElement).style.transform = "translateX(100%)";
					(document.getElementById('divHead') as HTMLElement).classList.remove('pageMetierActive');
					document.body.classList.remove("o-hidden");
					let section = document.getElementById("retailDistribution") as HTMLElement;
					section.style.transform = "translateX(0)";
					section.style.filter = `brightness(1)`;
				}
				else if (this.slider.id === "immoCard") {
					(document.getElementById("immoRight") as HTMLElement).style.transform = "translateX(100%)";
					(document.getElementById('divHead') as HTMLElement).classList.remove('pageMetierActive');
					document.body.classList.remove("o-hidden");
					let section = document.getElementById("realEstate") as HTMLElement;
					section.style.transform = "translateX(0)";
					section.style.filter = `brightness(1)`;
				}
				else if (this.slider.id === "financeCard") {
					(document.getElementById("financeRight") as HTMLElement).style.transform = "translateX(100%)";
					(document.getElementById('divHead') as HTMLElement).classList.remove('pageMetierActive');
					document.body.classList.remove("o-hidden");
					let section = document.getElementById("finance") as HTMLElement;
					section.style.transform = "translateX(0)";
					section.style.filter = `brightness(1)`;
				}
				else if (this.slider.id === "grpCard") {
					(document.getElementById("grpRight") as HTMLElement).style.transform = "translateX(100%)";
					(document.getElementById('divHead') as HTMLElement).classList.remove('pageMetierActive');
					document.body.classList.remove("o-hidden");
					let section = document.getElementById("groupe") as HTMLElement;
					section.style.transform = "translateX(0)";
					section.style.filter = `brightness(1)`;

					let list = document.querySelectorAll("#grpCard ul li ul li") as NodeListOf<HTMLLIElement>;
					let p = document.querySelectorAll("#grpCard ul li p") as NodeListOf<HTMLLIElement>;
					(document.querySelector("#grpCard ul h3") as HTMLElement).classList.remove("classBefore");

					for (let i = 0; i < p.length; i++) {
						setTimeout(() => {
							p[i].style.transform = "translateX(150%)";
						}, 100 + (50 * i));
					}

					for (let i = 0; i < list.length; i++) {
						setTimeout(() => {
							list[i].style.transform = "translateY(-100%)";
						}, 100 + (50 * i));
					}
				}
			}

			return;
		}

		this.currentSlide = index;
	}
}
