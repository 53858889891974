import { gsap } from 'gsap';
import { SplitText } from "gsap/dist/SplitText";

interface AnimationeMetierHomeProps {
	arrayOfSection: HTMLElement[]
}

export class AnimationeMetierHome {
	arrayOfSection: HTMLElement[];

	constructor({ arrayOfSection }: AnimationeMetierHomeProps) {
		this.arrayOfSection = arrayOfSection;

		this.bind(arrayOfSection[0]);
		this.bind(arrayOfSection[1]);
		this.bind(arrayOfSection[2]);
	}

	private bind(section: HTMLElement) {
		let counter = 0;

		const { scrollTop, clientHeight } = document.documentElement;
		const view: any = section?.getBoundingClientRect().top;
		const scrollTopAndView = scrollTop + view;

		if (scrollTop > (scrollTopAndView - 500)) {
			this.appear(section);
		} else {
			(document.getElementById('home') as HTMLElement).addEventListener('scroll', () => {
				const view: any = section?.getBoundingClientRect().top;
				const scrollTopAndView = scrollTop + view;

				if (scrollTop > (scrollTopAndView - (clientHeight / 1.5))) {
					if (counter < 1) {
						setTimeout(() => {
							this.appear(section);
						}, 500);
						counter++;
					}
				}
			});
		}
	}

	private appear(section: HTMLElement) {
		let h2 = section.querySelector("h2") as HTMLElement,
			p = section.querySelector("p") as HTMLElement,
			href = section.querySelector("a") as HTMLElement,
			hrefMobile = section.querySelector(".linkMobile") as HTMLElement,
			// svg = section.querySelector("svg") as SVGElement,
			// svgMobile = section.querySelector(".linkMobile svg") as SVGElement,
			titleSplit = new SplitText(h2, { type: "words, chars" }),
			tl = gsap.timeline();

		tl.fromTo(h2, { opacity: 0 }, { opacity: 1, duration: 0.5 })
			.fromTo(titleSplit.chars, { opacity: 0 }, { opacity: 1, duration: 0.5, stagger: { each: 0.10 } })
			.fromTo(p, { height: "0" }, { height: "100%", duration: 2 }, "<2")
			.fromTo(href, { opacity: 0 }, { opacity: 1 })
			.fromTo(hrefMobile, { opacity: 0 }, { opacity: 1 })
		// .fromTo(href, { outline: "0px solid #fff" }, { outline: "1px solid #fff" }, ">1")
		// .fromTo(hrefMobile, { outline: "0px solid #fff" }, { outline: "1px solid #fff" }, "<")
		// .fromTo(href.children[0], { x: "-150px" }, { x: "0px", duration: 0.5 }, "<")
		// .fromTo(hrefMobile.children[0], { x: "-150px" }, { x: "0px", duration: 0.5 }, "<")
		// .fromTo(svg, { y: "-50px" }, { y: "0px", duration: 0.5 }, "<")
		// .fromTo(svgMobile, { y: "-50px" }, { y: "0px", duration: 0.5 }, "<")
		// .fromTo(href, { borderRadius: "0px" }, { borderRadius: "50px", duration: 3 }, "<0.5")
		// .fromTo(hrefMobile, { borderRadius: "0px" }, { borderRadius: "50px", duration: 3 }, "<")

		// setTimeout(() => {
		// 	href.children[2].classList.add("c1");
		// 	href.children[3].classList.add("c2");

		// 	hrefMobile.children[1].classList.add("c1");
		// 	hrefMobile.children[2].classList.add("c2");
		// }, 3500);
	}
}
