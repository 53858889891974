import { gsap } from 'gsap';
import { SplitText } from "gsap/dist/SplitText";
import { AjaxSearch } from './partials/ajaxSearch';
import { AnimActuHome } from './partials/animationActuHome';
import animationContact from "./partials/animationContact";
import { AnimGroupe } from './partials/animationGroupe';
import { AnimationeMetierHome } from './partials/animationMetierHome';
import { animeOnEvent } from './partials/AnimationOrganigramme';
import { Header } from './partials/header';
import headVideoAnimation from './partials/headVideoAnimation';
import './partials/menu';
import { MenuMobile } from './partials/menuMobile';
import { ShowPageMetier } from './partials/showPageMetierMobile';
import { SliderActus } from './partials/sliderActusHome';
import { SliderPageMetier } from './partials/sliderPageMetier';
import { SliderPageMetierMobile } from './partials/sliderPageMetierMobile';

let lang = document.getElementsByTagName('html')[0].getAttribute('lang');

const html = document.querySelector('html');

// --Bug on home page so add overflow hidden only on the homepage--
if (window.location.pathname === "/" || window.location.pathname === '/en/home/') {
    html!.style.overflow = 'hidden';
} else {
    html!.style.overflow = 'visible';
}

// Make redirection for translation

const link = document.querySelector('.language');
let href = document.querySelector('.language')!.getAttribute('href');


if (window.location.pathname === '/') {
    link!.setAttribute('href', window.location.origin + '/en/home/')
}
if (window.location.pathname === '/en/home/') {
    link!.setAttribute('href', window.location.origin + '/')
}
if (window.location.pathname === '/immobilier/') {
    link!.setAttribute('href', window.location.origin + '/en/real-estate/')
}
if (window.location.pathname === '/en/real-estate/') {
    link!.setAttribute('href', window.location.origin + '/immobilier/')
}
if (window.location.pathname === '/finance-investissement/') {
    link!.setAttribute('href', window.location.origin + '/en/finance-and-investment/')
}
if (window.location.pathname === '/en/finance-and-investment/') {
    link!.setAttribute('href', window.location.origin + '/finance-investissement/')
}
if (window.location.pathname === '/distribution/') {
    link!.setAttribute('href', window.location.origin + '/en/retail-distribution/')
}
if (window.location.pathname === '/en/retail-distribution/') {
    link!.setAttribute('href', window.location.origin + '/distribution/')
}
if (window.location.pathname === '/recrutement/') {
    link!.setAttribute('href', window.location.origin + '/en/recruitment/')
}
if (window.location.pathname === '/en/recruitment/') {
    link!.setAttribute('href', window.location.origin + '/recrutement/')
}
if (window.location.pathname === '/actualites/') {
    link!.setAttribute('href', window.location.origin + '/en/news/')
}
if (window.location.pathname === '/en/news/') {
    link!.setAttribute('href', window.location.origin + '/actualites/')
}
if (window.location.pathname === '/contact/') {
    link!.setAttribute('href', window.location.origin + '/en/contact-en/')
}
if (window.location.pathname === '/en/contact-en/') {
    link!.setAttribute('href', window.location.origin + '/contact/')
}
if (window.location.pathname === '/projet/') {
    link!.setAttribute('href', window.location.origin + '/en/project/')
}
if (window.location.pathname === '/en/project/') {
    link!.setAttribute('href', window.location.origin + '/projet/')
}
new Header({
    header: document.getElementById('header') as HTMLElement
});

if (window.matchMedia("(max-width: 1025px)").matches) {
    new MenuMobile({
        arrayOfElement: [
            document.querySelector(".left-section__text--" + (lang === "en-US" ? "real-estate" : "immobilier")) as HTMLElement,
            document.querySelector(".left-section__text--" + (lang === "en-US" ? "retail-distribution" : "distribution")) as HTMLElement,
            document.querySelector(".left-section__text--" + (lang === "en-US" ? "finance-and-investment" : "finance--investissement")) as HTMLElement,
            document.querySelector(".left-section__text--" + (lang === "en-US" ? "recruitment" : "recrutement")) as HTMLElement
        ]
    });
}

//  -- MENU --
if (document.getElementById('menu')) {
    let input = '';

    setInterval(() => {
        if (input !== (<HTMLInputElement>document.getElementById("article_search")).value) {
            new AjaxSearch({
                inputValue: (<HTMLInputElement>document.getElementById("article_search")).value,
            });
        }

        input = (<HTMLInputElement>document.getElementById("article_search")).value;
    }, 1000);
}

// -- HOME --
if (document.getElementById('home')) {
    (document.getElementById('divHead') as HTMLElement).style.animationName = "fadeHeader"; // Apparition header
    headVideoAnimation(); // Video intro
    animeOnEvent(); // Animation Organigramme

    // Animation Tilte intro
    let title = document.getElementById('homeTitle') as HTMLElement,
        ctaScroll = document.getElementById('divCta') as HTMLElement,
        arrowElement = Array.from(document.querySelectorAll<HTMLElement>(".ctaScroll")),
        titleSplit = new SplitText(title, { type: "words, chars" }),
        tl = gsap.timeline();

    tl.fromTo(titleSplit.chars, { opacity: 0 }, { opacity: 1, duration: 1, stagger: { each: 0.10 } }, "<1");
    setTimeout(() => {
        ctaScroll.style.opacity = "1";
        //ctaScroll.style.transform = "translateY(0px)";
        if (/(iPhone|iPod|iPad)/i.test(navigator.userAgent) && window.innerWidth < 510 && window.innerWidth > 410 && navigator.userAgent.match(/chrome|chromium|crios/i)) {
            ctaScroll.style.transform = "translateY(0px)";
            arrowElement.forEach(element => element.style.bottom = "125px")

        } else {
            ctaScroll.style.transform = "translateY(0px)";
        }
    }, 5000);

    // Actus Slide
    new SliderActus({
        slider: document.querySelector('#actualitesHome .slider') as HTMLElement,
        btnNext: document.querySelector('#actualitesHome .next') as HTMLElement,
        btnPrev: document.querySelector('#actualitesHome .prev') as HTMLElement
    });

    // -- MOBILE --
    if (window.matchMedia("(max-width: 1025px)").matches) {
        // Slide page Distribution | Immobilier | Finance | Groupe
        new SliderPageMetierMobile({
            slider: document.querySelector('#distribRight .slider') as HTMLElement
        });
        new SliderPageMetierMobile({
            slider: document.querySelector('#immoRight .slider') as HTMLElement
        });
        new SliderPageMetierMobile({
            slider: document.querySelector('#financeRight .slider') as HTMLElement
        });
        new SliderPageMetierMobile({
            slider: document.querySelector('#grpRight .slider') as HTMLElement
        });

        // Affichage page Metier
        new ShowPageMetier({
            arrayOfElement: [
                document.getElementById("mobileDistrib") as HTMLElement,
                document.getElementById("mobileImmo") as HTMLElement,
                document.getElementById("mobileFinance") as HTMLElement,
                document.getElementById("mobileGroupe") as HTMLElement
            ]
        });
    }

    // Animation d'apparition au scroll des cards actualites
    new AnimActuHome({
        homeActu: document.getElementById('homeActu') as HTMLElement,
        cardSlide: document.querySelectorAll('.slideActu') as NodeListOf<HTMLElement>,
        cardContent: document.querySelectorAll('.cardContent') as NodeListOf<HTMLElement>,
        cardTitle: document.querySelectorAll(".cardTitle") as NodeListOf<HTMLElement>,
        cardDate: document.querySelectorAll(".cardDate") as NodeListOf<HTMLElement>,
        cardDescription: document.querySelectorAll(".cardText") as NodeListOf<Element>
    });

    // AnimationGroupe();
    new AnimGroupe({
        section: document.getElementById('groupe') as HTMLElement
    });

    // Animation apparition home metier
    new AnimationeMetierHome({
        arrayOfSection: [
            document.getElementById("realEstate") as HTMLElement,
            document.getElementById("finance") as HTMLElement,
            document.getElementById("retailDistribution") as HTMLElement,
        ]
    });
}

// -- PAGE METIER -- slide
if (document.getElementById("main-tolefi-immobilier")) {
    new SliderPageMetier({
        slider: document.querySelector('#main-tolefi-immobilier .slider') as HTMLElement,
        btnNext: document.querySelector('#main-tolefi-immobilier .slideNext') as HTMLElement,
        btnPrev: document.querySelector('#main-tolefi-immobilier .slidePrev') as HTMLElement,
        list: document.querySelector('#main-tolefi-immobilier .slide1 .index') as HTMLElement
    });
    let footer = document.querySelector('.footer') as any;
    footer.style.display = "none";
}

if (document.getElementById("main-tolefi-finance")) {
    new SliderPageMetier({
        slider: document.querySelector('#main-tolefi-finance .slider') as HTMLElement,
        btnNext: document.querySelector('#main-tolefi-finance .slideNext') as HTMLElement,
        btnPrev: document.querySelector('#main-tolefi-finance .slidePrev') as HTMLElement,
        list: document.querySelector('#main-tolefi-finance .slide1 .index') as HTMLElement
    });
    let footer = document.querySelector('.footer') as any;
    footer.style.display = "none";
}

if (document.getElementById("main-tolefi-distribution")) {
    new SliderPageMetier({
        slider: document.querySelector('#main-tolefi-distribution .slider') as HTMLElement,
        btnNext: document.querySelector('#main-tolefi-distribution .slideNext') as HTMLElement,
        btnPrev: document.querySelector('#main-tolefi-distribution .slidePrev') as HTMLElement,
        list: document.querySelector('#main-tolefi-distribution .slide1 .index') as HTMLElement
    });
    let footer = document.querySelector('.footer') as any;
    footer.style.display = "none";
}

// -- CONTACT --
if (document.getElementById('main-contact')) {
    animationContact();
    let footer = document.querySelector('.footer') as any;
    footer.style.display = "none";
}
