interface ShowPageMetierProps {
	arrayOfElement: Array<HTMLElement>;
}

export class ShowPageMetier {
	arrayOfElement: Array<HTMLElement>;

	constructor({ arrayOfElement }: ShowPageMetierProps) {
		this.arrayOfElement = arrayOfElement;

		this.bind();
	}

	private bind() {
		this.arrayOfElement.forEach(element => {
			element.addEventListener("click", () => {
				if (element.id !== "mobileGroupe") (document.getElementById('divHead') as HTMLElement).classList.add('pageMetierActive');
				(document.body as HTMLElement).classList.add("o-hidden");

				let section = document.getElementById((element.id === "mobileImmo" ? "realEstate" : (element.id === "mobileDistrib" ? "retailDistribution" : "finance"))) as HTMLElement;

				if (element.id === "mobileImmo") {
					(document.getElementById("immoRight") as HTMLElement).style.transform = "translateX(0%)";
				}
				else if (element.id === "mobileDistrib") {
					(document.getElementById("distribRight") as HTMLElement).style.transform = "translateX(0%)";
				}
				else if (element.id === "mobileFinance") {
					(document.getElementById("financeRight") as HTMLElement).style.transform = "translateX(0%)";
				}
				else if (element.id === "mobileGroupe") {
					let list = document.querySelectorAll("#grpCard ul li ul li") as NodeListOf<HTMLLIElement>;
					let p = document.querySelectorAll("#grpCard ul li p") as NodeListOf<HTMLLIElement>;
					(document.getElementById("grpRight") as HTMLElement).style.transform = "translateX(0%)";
					(document.querySelector("#grpCard ul h3") as HTMLElement).classList.add("classBefore");

					for (let i = 0; i < p.length; i++) {
						setTimeout(() => {
							p[i].style.transform = "translateX(0%)";
						}, 100 + (50 * i));
					}

					for (let i = 0; i < list.length; i++) {
						setTimeout(() => {
							list[i].style.transform = "translateY(0%)";
						}, 500 + (50 * i));
					}
				}

				if (element.id !== "mobileGroupe") {
					section.style.transform = "translateX(-150px)";
					section.style.filter = `brightness(0.8)`;
				}
			});
		});
	};
}
