import { gsap } from 'gsap';

const firstCircle: HTMLElement | null = document.querySelector('#first-circle');
const secondCircle: HTMLElement | null = document.querySelector('#second-circle');
const thirdCircle: HTMLElement | null = document.querySelector('#third-circle');
const title: HTMLElement | null = document.querySelector('#tolefi');
const titleSectionDistribution: HTMLElement | null = document.querySelector('.section-distribution');
const titleSectionImmobilier: HTMLElement | null = document.querySelector('.section-immobilier');
const titleSectionFinance: HTMLElement | null = document.querySelector('.section-finance');
const borderVertical: NodeListOf<Element> = document.querySelectorAll('.border-verticale');
const borderHorizontale: NodeListOf<Element> = document.querySelectorAll('.border-horizontale');
const borderArray = [...borderVertical, ...borderHorizontale];
const littleBorder: NodeListOf<Element> = document.querySelectorAll('.little-border');
const firstRow: NodeListOf<Element> = document.querySelectorAll('.first-row > *');
const secondRow: NodeListOf<Element> = document.querySelectorAll('.second-row > *');
const thirdRow: NodeListOf<Element> = document.querySelectorAll('.third-row > *');
const fourthRow: NodeListOf<Element> = document.querySelectorAll('.fourth-row > *');
const fifthRow: NodeListOf<Element> = document.querySelectorAll('.fifth-row > *');
const anchorChangeSection: any | null = document.querySelector('#organigrammeParent > .cta > a');
const navigation: any | null = document.querySelector('#organigrammeParent > .cta > p');
const navigationSpan: any | null = document.querySelector('#organigrammeParent > .cta > p > span');
const borderMiddleRight: HTMLElement | null = document.querySelector(".border-middle-right");
const borderMiddleLeft: HTMLElement | null = document.querySelector(".border-middle-left");

const secondAnimationCircle = (element: any, tl: any, start: number, end: number, duration?: string,
) => { tl.fromTo(element, { x: start }, { x: end }, duration) }

const thirdAnimationCircle = (element: any, tl: any, start: number, end: number, duration?: string,
) => { tl.fromTo(element, { y: start }, { y: end }, duration) }

const fourthAnimationCircle = (element: any, tl: any, startY: number, endY: number, endX: number, duration?: string,
) => { tl.fromTo(element, { y: startY }, { y: endY, x: endX }, duration) }

const fifthAnimationCircle = (element: any, tl: any, endY: number, endX: number, scale: number, duration?: string
) => { tl.to(element, { y: endY, x: endX, scale: scale }, duration) }

const sixthAnimationCircle = (element: HTMLElement | null, tl: any, x: number, y: number) => {
    tl.to(element, { x: x, y: y, height: 60, width: 250, fontSize: 20, borderRadius: '30px', border: '2px solid white', }, '<',)
}

export const organigrammeOnMobile = () => {
    let checkAnimation: number = 0;

    /* All Timeline */
    const tl = gsap.timeline();
    const tlDistribution = gsap.timeline();
    const tlFinance = gsap.timeline();
    const tlImmobilier = gsap.timeline();
    const tlPromotion = gsap.timeline();
    const tlFonciere = gsap.timeline();

    /* First rotation */
    secondAnimationCircle(firstCircle, tl, 0, 20)
    secondAnimationCircle(secondCircle, tl, 0, -20, '<')
    thirdAnimationCircle(thirdCircle, tl, 0, 40, '<')
    /* Second rotation */
    fourthAnimationCircle(firstCircle, tl, 0, -20, 0)
    fourthAnimationCircle(secondCircle, tl, 0, 40, -30, '<')
    secondAnimationCircle(thirdCircle, tl, 0, 30, '<')
    /* Move the title to the top */
    tl.fromTo(title, { opacity: 1 }, { opacity: 0 }, '-=1')
    /* Scale Circle on the side */
    if (window.matchMedia("(max-width: 375px)").matches) {
        fifthAnimationCircle(firstCircle, tl, -144, -2, 1.5)
        fifthAnimationCircle(thirdCircle, tl, -32, -1, 1.5, "<")
        fifthAnimationCircle(secondCircle, tl, 75, -0, 1.5, "<")
    } else {
        fifthAnimationCircle(firstCircle, tl, -110, -2, 2)
        fifthAnimationCircle(thirdCircle, tl, 40, -1, 2, "<")
        fifthAnimationCircle(secondCircle, tl, 175, -0, 2, "<")
    }
    /* Subtitle apparition */
    if (window.matchMedia("(max-width: 375px)").matches) {
        tl.fromTo(titleSectionDistribution, { opacity: 0, y: 30 }, { opacity: 1, y: -33, x: 0 }, "-=0.5")
        tl.fromTo(titleSectionImmobilier, { opacity: 0, y: 30 }, { opacity: 1, y: -75, x: 0 }, "-=0.5")
        tl.fromTo(titleSectionFinance, { opacity: 0, y: 30 }, { opacity: 1, y: -101, x: 0 }, "-=0.5")
    } else {
        tl.fromTo(titleSectionDistribution, { opacity: 0, y: 30 }, { opacity: 1, y: 0, x: 0 }, "-=0.5")
        tl.fromTo(titleSectionImmobilier, { opacity: 0, y: 30 }, { opacity: 1, y: 0, x: 0 }, "-=0.5")
        tl.fromTo(titleSectionFinance, { opacity: 0, y: 30 }, { opacity: 1, y: 0, x: 0 }, "-=0.5")
    }

    tl.to(title, { y: -258 })
        .to(title, { opacity: 1 })


    borderMiddleRight!.style.scale = "0";
    borderMiddleLeft!.style.scale = "0";

    window.addEventListener("click", (e) => {
        if (e.target === firstCircle) {
            borderMiddleLeft!.style.scale = "1";
            if (tlDistribution.reversed()) {
                tlDistribution.play()
                anchorChangeSection.style.display = "none"
                navigation.style.display = "block"
                checkAnimation = 1;
            } else {
                /* Move circle to the left */
                if (firstCircle!.classList.contains('active')) {
                    return null
                } else {
                    firstCircle!.classList.add('active')
                    anchorChangeSection.style.display = "none"
                    navigation.style.display = "block"
                    checkAnimation = 1;
                    tlDistribution.to(firstCircle, { x: -60 })
                        /* Circle turn off */
                        .fromTo(secondCircle, { opacity: 1 }, { opacity: 0 }, "<")
                        .fromTo(thirdCircle, { opacity: 1 }, { opacity: 0 }, "<")
                        .fromTo(titleSectionImmobilier, { opacity: 1 }, { opacity: 0 }, "<")
                        .fromTo(titleSectionFinance, { opacity: 1 }, { opacity: 0 }, "<")
                        .to(secondCircle, { display: "none" }, "<")
                        .to(thirdCircle, { display: "none" }, "<")
                    /* Move SubTitle */
                    /* Move Title Distribution to the top */
                    sixthAnimationCircle(firstCircle, tlDistribution, -1, -190)
                    tlDistribution.to(firstCircle, { scale: 1 }, '<')
                    tlDistribution.to(titleSectionDistribution, {
                        x: 25, y: -9, height: 60,
                        width: 250,
                        fontSize: 20,

                    }, "<")
                    tlDistribution.fromTo(borderArray[0], { opacity: 0, scale: 0 }, { opacity: 1, scale: 1, transformOrigin: "0% 50%" })
                        /* Animation on little border */
                        .fromTo(littleBorder[0], { x: "-400%" }, { x: 0, opacity: 1 })
                        .fromTo(littleBorder[1], { x: "-400%" }, { x: 0, opacity: 1 }, "<")
                        .fromTo(littleBorder[2], { x: "-400%" }, { x: 0, opacity: 1 }, "<")
                        .fromTo(littleBorder[3], { x: "-400%" }, { x: 0, opacity: 1 }, "<")
                        .fromTo(littleBorder[4], { x: "-400%" }, { x: 0, opacity: 1 }, "<")
                        /* Animation on content */
                        .to(firstRow[0], { x: 0 }, "<")
                        .to(secondRow[0], { x: 0 }, "<")
                        .to(thirdRow[0], { x: 0 }, "<")
                        .to(fourthRow[0], { x: 0 }, "<")
                        .to(fifthRow[0], { x: 0 }, "<")
                }

            }
        }

        if (e.target === secondCircle) {
            /* Move circle to the left */
            if (tlFinance.reversed()) {
                tlFinance.play()
                anchorChangeSection.style.display = "none"
                navigation.style.display = "block"
                checkAnimation = 2
            } else {
                if (secondCircle!.classList.contains('active')) {
                    return null
                } else {
                    secondCircle!.classList.add('active');
                    borderMiddleRight!.style.scale = "1";
                    anchorChangeSection.style.display = "none"
                    navigation.style.display = "block"
                    checkAnimation = 2;
                    /* Circle turn off */
                    tlFinance.fromTo(firstCircle, { opacity: 1 }, { opacity: 0 }, "<")
                        .fromTo(thirdCircle, { opacity: 1 }, { opacity: 0 }, "<")
                        .fromTo(titleSectionImmobilier, { opacity: 1 }, { opacity: 0 }, "<")
                        .fromTo(titleSectionDistribution, { opacity: 1 }, { opacity: 0 }, "<")
                        .to(firstCircle, { display: "none" })
                        .to(thirdCircle, { display: "none" }, "<")
                    /* Move SubTitle */
                    tlFinance.to(titleSectionFinance, {
                        x: 24, y: -432, height: 60,
                        width: 250,
                        fontSize: 20,
                    }, "<")
                    if (window.matchMedia("(max-width: 375px)").matches) {
                        sixthAnimationCircle(secondCircle, tlFinance, -1, -189)
                    } else {
                        sixthAnimationCircle(secondCircle, tlFinance, -1, -184)
                    }
                    tlFinance.to(secondCircle, { scale: 1 }, '<')
                    tlFinance.fromTo(borderArray[2], { opacity: 0, scale: 0 }, { opacity: 1, scale: 1, transformOrigin: "0% 50%" })
                        .fromTo(littleBorder[0], { x: "-400%" }, { x: 0, opacity: 1 })
                        .fromTo(littleBorder[1], { x: "-400%" }, { x: 0, opacity: 1 }, "<")
                        .fromTo(littleBorder[2], { x: "-400%" }, { x: 0, opacity: 1 }, "<")
                        .to(firstRow[3], { x: 0 }, "<")
                        .to(secondRow[3], { x: 0 }, "<")
                        .to(thirdRow[3], { x: 0 }, "<")
                }
            }
        }

        if (e.target === thirdCircle) {
            borderMiddleLeft!.style.scale = "1";
            if (tlImmobilier.reversed()) {
                tlImmobilier.play()
                anchorChangeSection.style.display = "none"
                navigation.style.display = "block"
                checkAnimation = 3
            } else {
                if (thirdCircle!.classList.contains('active')) {
                    return null
                } else {
                    thirdCircle!.classList.add('active')
                    /* Move circle to the left */
                    anchorChangeSection.style.display = "none"
                    navigation.style.display = "block"
                    checkAnimation = 3
                    /* Circle turn off */
                    tlImmobilier.fromTo(firstCircle, { opacity: 1 }, { opacity: 0 }, "<")
                        .fromTo(secondCircle, { opacity: 1 }, { opacity: 0 }, "<")
                        .fromTo(titleSectionDistribution, { opacity: 1 }, { opacity: 0 }, "<")
                        .fromTo(titleSectionFinance, { opacity: 1 }, { opacity: 0 }, "<")
                        .to(firstCircle, { display: "none" })
                        .to(secondCircle, { display: "none" }, "<")
                    tlImmobilier.to(titleSectionImmobilier, {
                        x: 24, y: -299, height: 60,
                        width: 250,
                        fontSize: 20,
                    }, "<")
                    sixthAnimationCircle(thirdCircle, tlImmobilier, 1, -187)
                    tlImmobilier.to(thirdCircle, { scale: 1 }, '<')
                    /* Move SubTitle */
                    if (window.matchMedia("(max-width:280px)").matches) {
                        tlImmobilier.fromTo(firstRow[1], { x: -400, y: -50 }, { x: -86, y: 166 })
                        tlImmobilier.fromTo(firstRow[2], { x: 400, y: 500 }, { x: -30, y: 166 }, "<")
                    } else {
                        if (window.matchMedia("(max-width:375px)").matches) {
                            tlImmobilier.fromTo(firstRow[1], { x: -400, y: -50 }, { x: -111, y: 166 })
                            tlImmobilier.fromTo(firstRow[2], { x: 400, y: 500 }, { x: -42, y: 166 }, "<")
                        } else {
                            tlImmobilier.fromTo(firstRow[1], { x: -400, y: -50 }, { x: -111, y: 166 })
                            tlImmobilier.fromTo(firstRow[2], { x: 400, y: 500 }, { x: -42, y: 166 }, "<")
                        }
                    }

                }
            }
        }

        if (e.target === firstRow[1]) {
            borderMiddleRight!.style.scale = "1";
            if (tlPromotion.reversed()) {
                tlPromotion.play()
                checkAnimation = 4
            } else {
                if (firstRow[1]!.classList.contains('active')) {
                    return null
                } else {
                    firstRow[1]!.classList.add('active')
                    anchorChangeSection.style.display = "none"
                    navigation.style.display = "block"
                    checkAnimation = 4

                    if (window.matchMedia("(max-width: 280px)").matches) {
                        tlPromotion.to(firstRow[1], { y: -118, x: -70, height: 60, width: 250, fontSize: 20, borderRadius: '30px', border: '2px solid white', })
                    } else {
                        tlPromotion.to(firstRow[1], { y: -118, x: -53, height: 60, width: 250, fontSize: 20, borderRadius: '30px', border: '2px solid white', })
                    }
                    // tlPromotion.to(firstRow[1], {y: -118, x:-70,  height: 60,width: 250, fontSize: 20,borderRadius: '30px', border: '2px solid white',})
                    tlPromotion.to(firstRow[2], { opacity: 0 }, "<")
                        .to(titleSectionImmobilier, { opacity: 0 }, "<")
                        .to(thirdCircle, { opacity: 0 }, "<")
                    tlPromotion.fromTo(borderArray[2], { opacity: 0, scale: 0 }, { opacity: 1, scale: 1, transformOrigin: "0% 50%" })
                        .fromTo(littleBorder[0], { x: "-400%" }, { x: 0, opacity: 1 })
                        .fromTo(littleBorder[1], { x: "-400%" }, { x: 0, opacity: 1 }, "<")
                        .fromTo(littleBorder[2], { x: "-400%" }, { x: 0, opacity: 1 }, "<")
                        .to(secondRow[1], { x: 0 }, "<")
                        .to(thirdRow[1], { x: 0 }, "<")
                        .to(fourthRow[1], { x: 0 }, "<")
                }
                tlPromotion.fromTo(firstRow[2], { scale: 1 }, { scale: 0 })
            }
        }

        if (e.target === firstRow[2]) {
            if (tlFonciere.reversed()) {
                tlFonciere.play()
                checkAnimation = 5
            } else {
                if (firstRow[2]!.classList.contains('active')) {
                    return null
                } else {
                    firstRow[2]!.classList.add('active')
                    anchorChangeSection.style.display = "none"
                    navigation.style.display = "block"
                    checkAnimation = 5
                    if (window.matchMedia("(max-width: 280px)").matches) {
                        tlFonciere.to(firstRow[2], { y: -118, x: -137, height: 60, width: 250, fontSize: 20, borderRadius: '30px', border: '2px solid white', })
                    } else {
                        tlFonciere.to(firstRow[2], { y: -118, x: -142, height: 60, width: 250, fontSize: 20, borderRadius: '30px', border: '2px solid white', })
                    }
                    tlFonciere.to(firstRow[1], { opacity: 0 }, "<")
                        .to(titleSectionImmobilier, { opacity: 0 }, "<")
                        .to(thirdCircle, { opacity: 0 }, "<")
                    if (window.matchMedia("(max-width:280px)").matches) {
                        // tlFonciere.fromTo(borderArray[0], { opacity: 0, scale: 0 }, { opacity: 1, scale: 1, transformOrigin: "0% 50%", width: "112%" })
                        tlFonciere.fromTo(borderArray[0], { opacity: 0, scale: 0 }, { opacity: 1, scale: 1, transformOrigin: "0% 50%", width: "77%" })
                    } else {
                        if (window.matchMedia("(max-width:375px)").matches) {
                            tlFonciere.fromTo(borderArray[0], { opacity: 0, scale: 0 }, { opacity: 1, scale: 1, transformOrigin: "0% 50%", width: "57%" })
                        } else {
                            tlFonciere.fromTo(borderArray[0], { opacity: 0, scale: 0 }, { opacity: 1, scale: 1, transformOrigin: "0% 50%", width: "84%" })
                        }

                    }
                    tlFonciere.fromTo(littleBorder[0], { x: "-400%" }, { x: 0, opacity: 1 })
                        .fromTo(littleBorder[1], { x: "-400%" }, { x: 0, opacity: 1 }, "<")
                        .fromTo(littleBorder[2], { x: "-400%" }, { x: 0, opacity: 1 }, "<")
                        .fromTo(littleBorder[3], { x: "-400%" }, { x: 0, opacity: 1 }, "<")
                        .fromTo(secondRow[2], { x: "-400%" }, { x: 0 }, "<")
                        .to(thirdRow[2], { x: 0 }, "<")
                        .to(fourthRow[2], { x: 0 }, "<")
                        .to(fifthRow[1], { x: 0 }, "<")

                }
                tlFonciere.fromTo(firstRow[1], { scale: 1 }, { scale: 0 })
            }
        }

        if (e.target === navigation || e.target === navigationSpan) {
            if (checkAnimation === 1) {
                tlDistribution.reverse()
                checkAnimation = 0
                navigation.style.display = "none"
                anchorChangeSection.style.display = "block"
            }
            if (checkAnimation === 2) {
                tlFinance.reverse()
                checkAnimation = 0
                navigation.style.display = "none"
                anchorChangeSection.style.display = "block"
                // navigation.style.display = "none"
                // anchorChangeSection.style.display = "inline"
            }
            if (checkAnimation === 3) {
                if (navigation.classList.contains('active')) {
                    return null
                } else {
                    tlImmobilier.reverse()
                    checkAnimation = 0
                    navigation.style.display = "none"
                    anchorChangeSection.style.display = "block"
                }
                // tlImmobilier.reverse()
                // checkAnimation = 0
                // navigation.style.display = "none"
                // anchorChangeSection.style.display = "block"
            } if (checkAnimation === 4) {
                tlPromotion.reverse()
                checkAnimation = 3
                navigation.classList.add('active')
                navigationSpan.classList.add('active')
                setTimeout(() => {
                    navigation.classList.remove('active')
                    navigationSpan.classList.remove('active')
                }, 1800)
            }
            if (checkAnimation === 5) {
                tlFonciere.reverse()
                checkAnimation = 3
                navigation.classList.add('active')
                navigationSpan.classList.add('active')
                setTimeout(() => {
                    navigation.classList.remove('active')
                    navigationSpan.classList.remove('active')
                }, 2000)
            }
        }
    })
}
