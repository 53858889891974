interface MenuMobileProps {
	arrayOfElement: Array<HTMLElement>;
}

export class MenuMobile {
	arrayOfElement: Array<HTMLElement>;

	constructor({ arrayOfElement }: MenuMobileProps) {
		this.arrayOfElement = arrayOfElement;

		this.bind();
	}

	private bind() {
		let isOpen = false;

		this.arrayOfElement.forEach((element) => {
			element.addEventListener("click", () => {
				if (window.matchMedia("(max-width: 1024px)").matches) {
					if (isOpen) {
						element.classList.remove("rotate");
						isOpen = false;

						if (window.matchMedia("(max-width: 767px)").matches) {
							if (element.classList[0] == "left-section__text--finance--investissement") {
								element.style.height = "60px";
							} else {
								element.style.height = "60px";
							}
						} else {
							element.style.height = "80px";
						}
					} else {
						if (window.matchMedia("(max-width: 767px)").matches) {
							if (element.classList[0] == "left-section__text--distribution") {
								element.style.height = "140px";
							} else {
								element.style.height = "140px";
							}
						} else {
							if (element.classList[0] == "left-section__text--distribution") {
								element.style.height = "170px";
							} else {
								element.style.height = "170px";
							}
						}
						element.classList.add("rotate");
						isOpen = true;
					}
				}
			});
		});
	};
}
