import { gsap } from 'gsap';
import { SplitText } from "gsap/dist/SplitText";

interface AnimActuHomeProps {
	homeActu: HTMLElement;
	cardSlide: NodeListOf<HTMLElement>;
	cardContent: NodeListOf<HTMLElement>;
	cardTitle: NodeListOf<HTMLElement>;
	cardDate: NodeListOf<HTMLElement>;
	cardDescription: NodeListOf<Element>;
}

export class AnimActuHome {
	homeActu: HTMLElement;
	cardSlide: NodeListOf<HTMLElement>;
	cardContent: NodeListOf<HTMLElement>;
	cardTitle: NodeListOf<HTMLElement>;
	cardDate: NodeListOf<HTMLElement>;
	cardDescription: NodeListOf<Element>;
	title;
	title2;
	title3;
	date;
	date2;
	date3;
	description;
	description2;
	description3;

	constructor({ homeActu, cardSlide, cardContent, cardTitle, cardDate, cardDescription }: AnimActuHomeProps) {
		this.homeActu = homeActu;
		this.cardSlide = cardSlide;
		this.cardContent = cardContent;
		this.cardTitle = cardTitle;
		this.cardDate = cardDate;
		this.cardDescription = cardDescription;

		this.title = this.cardTitle[0];
		this.title2 = this.cardTitle[1];
		this.title3 = this.cardTitle[2];

		this.date = this.cardDate[0];
		this.date2 = this.cardDate[1];
		this.date3 = this.cardDate[2];

		this.description = new SplitText(this.cardDescription[0], { type: "chars, lines" });
		this.description2 = new SplitText(this.cardDescription[1], { type: "chars, lines" });
		this.description3 = new SplitText(this.cardDescription[2], { type: "chars, lines" });

		this.bind();
		this.showImg();
	}

	private bind() {
		let counter = 0;

		const { scrollTop, clientHeight } = document.documentElement;
		const view: any = this.homeActu?.getBoundingClientRect().top;
		const scrollTopAndView = scrollTop + view;
		// let element = window.matchMedia("(max-width: 768px)").matches ? document : (document.getElementById('home') as HTMLElement);

		if (scrollTop > (scrollTopAndView - 500)) {
			this.appear();
		} else {
			(document.getElementById('home') as HTMLElement).addEventListener('scroll', () => {
				const view: any = this.homeActu?.getBoundingClientRect().top;
				const scrollTopAndView = scrollTop + view;

				if (scrollTop > (scrollTopAndView - (clientHeight / 1.5))) {
					if (counter < 1) {
						this.appear();
						counter++;
					}
				}
			});
		}
	}

	private appear() {
		const tl = gsap.timeline();
		let titleActu = document.getElementById('overTitleActu') as HTMLElement;
		//let allActu = document.getElementById('overAllActu') as HTMLElement;
		let allActu = document.querySelectorAll('.overAllActu') as NodeListOf<HTMLElement>;
		// let span = document.querySelectorAll('.bgNetwork') as NodeListOf<HTMLElement>;

		titleActu.style.transform = "translateY(0px)";
		allActu.forEach(element => {
			element.style.transform = "translateY(0px)";
		});

		// card 2 - Milieu
		this.cardAnim(this.cardSlide[1], this.title2, this.date2, this.description2, this.cardContent[1]);

		// card 1 - Gauche
		setTimeout(() => {
			this.cardAnim(this.cardSlide[0], this.title, this.date, this.description, this.cardContent[0]);
		}, 200);

		// card 3 - Droite
		setTimeout(() => {
			this.cardAnim(this.cardSlide[2], this.title3, this.date3, this.description3, this.cardContent[2]);
		}, 400);
	}

	// Animation d'apparition des card
	private cardAnim(card: HTMLElement, title: HTMLElement, date: HTMLElement, description: any, cardContent: HTMLElement) {
		const tl = gsap.timeline();
		let arrow = document.getElementById('carouselArrow') as HTMLElement;
		card.style.transform = "translateY(0)";

		setTimeout(() => {
			title.style.opacity = "1";
			title.style.transform = "translateY(0)";
			date.style.opacity = "1";
			date.style.transform = "translateY(0)";
			// span.style.transform = "scale(1)";
		}, 500);

		setTimeout(() => {
			tl.fromTo(description.chars, { opacity: 0 }, { opacity: 1, stagger: { each: 0.02 } });
			// span.style.width = "100%";

			if (window.matchMedia("(min-width: 1440px)").matches) {
				cardContent.style.height = "45%";
			} else if (window.matchMedia("(min-width: 1025px)").matches) {
				cardContent.style.height = "50%";
			}
		}, 1200);

		setTimeout(() => {
			arrow.style.transform = "translateY(0)";
			arrow.style.opacity = "1";
		}, 2000);
	}

	// Click sur les bare en format mobile pour reduire la card
	private showImg() {
		const spanBare = document.querySelectorAll(".showImgActu") as NodeListOf<Element>;

		for (const bare of spanBare) {
			let isOpen = false;

			bare.addEventListener("click", (event) => {
				let parent = (<HTMLElement>event.target).parentElement as HTMLElement;
				let network = parent.children[1] as HTMLElement;

				if (isOpen) {
					parent.style.height = "100%";
					network.style.transform = "scale(1)";
					network.style.opacity = "1";
					isOpen = false;
				} else {
					parent.style.height = "50%";
					network.style.transform = "scale(0)";
					network.style.opacity = "0";
					isOpen = true;
				}
			});

			// si on slide la bare au lieu de clicker
			// let test = 0;

			// bare.addEventListener("touchmove", (event) => {
			// 	event.preventDefault();
			// 	test++;
			// 	let parent = (<HTMLElement>event.target).parentElement as HTMLElement;
			// 	let network = parent.children[1] as HTMLElement;

			// 	console.log(isOpen);

			// 	if (!isOpen) {
			// 		parent.style.height = `${100 - test}%`;
			// 		network.style.transform = "scale(1)";
			// 		network.style.opacity = "1";
			// 		// isOpen = false;
			// 	} else {

			// 	}
			// });

			// bare.addEventListener("touchend", (event) => {

			// 	// let parent = (<HTMLElement>event.target).parentElement as HTMLElement;
			// 	// let network = parent.children[1] as HTMLElement;

			// 	// if (!isOpen) {
			// 	// 	network.style.transform = "scale(0)";
			// 	// 	network.style.opacity = "0";
			// 	// 	isOpen = true;
			// 	// }
			// })
		}
	}
}
