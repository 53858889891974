import { gsap, TweenLite, TweenMax } from 'gsap';

interface SliderPageMetierProps {
	slider: HTMLElement;
	btnPrev: HTMLElement;
	btnNext: HTMLElement;
	list: HTMLElement;
}

export class SliderPageMetier {
	dragging = false;
	pointerPos = { x: 0 };
	lastPointerPos = { x: 0 };
	sliderPos = { x: 0 };
	lastSliderPos = { x: 0 };
	totalDist = 0;
	currentSlide = 0;
	momentumTween: TweenLite | TweenMax | null = null;
	distLog: number[] = [];
	duration = 2;
	slideWidth = 0;
	inView = true;
	isEnterMouse = true;
	nbrPerSlide = 1;
	observer: IntersectionObserver;
	interval?: number;
	slider: HTMLElement;
	btnPrev: HTMLElement;
	btnNext: HTMLElement;
	list: HTMLElement;
	slidesWrapper: HTMLUListElement;
	slides: NodeListOf<HTMLLIElement>;
	links: NodeListOf<HTMLAnchorElement>;
	pageX = 0;

	constructor({ btnNext, btnPrev, slider, list }: SliderPageMetierProps) {
		this.slider = slider;
		this.btnPrev = btnPrev;
		this.btnNext = btnNext;
		this.list = list;
		this.slidesWrapper = this.slider.querySelector('ul')!;
		this.slides = this.slidesWrapper.querySelectorAll(':scope > li');
		this.links = this.slidesWrapper.querySelectorAll('a');

		this.bind();
		this.onResize();

		this.observer = new IntersectionObserver(
			([entry]) => (this.inView = entry.isIntersecting),
			{ threshold: 0 },
		);
		this.observer.observe(this.slider);
	}

	private bind() {
		const urlParams = new URLSearchParams(window.location.search);
		const urlSlide = urlParams.get('slide');

		if (urlSlide) {
			if (urlSlide === "1") {
				this.setIndex(1);
				this.setSliderPos(this.currentSlide);
			} else if (urlSlide === "2") {
				this.setIndex(2);
				this.setSliderPos(this.currentSlide);
			} else if (urlSlide === "3") {
				this.setIndex(3);
				this.setSliderPos(this.currentSlide);
			}
		}

		this.btnPrev.addEventListener('click', this.prev);
		this.btnNext.addEventListener('click', this.next);

		this.links.forEach((link) => {
			link.addEventListener('click', (e) => {
				if (this.totalDist !== 0) e.preventDefault();
			});
		});

		window.addEventListener('keydown', (e) => this.onKeyDown(e.key));
		window.addEventListener('resize', () => this.onResize());

		// Click sur la list du slide 1 pour set l'index
		Array.from(this.list.children).forEach((li, key) => {
			li.addEventListener("click", () => {
				this.setIndex(key + 1);
				this.setSliderPos(this.currentSlide);
			});

			li.addEventListener("mouseover", (e) => {
				let div = (document.querySelector(".divFigureSlide1") as HTMLElement);

				if (this.slider.id === "slide-immobilier" || this.slider.id === "slide-distribution") {
					(div.children[key].children[0] as HTMLElement).style.top = "40px";
				} else if (this.slider.id === "slide-finance") {
					(div.children[key] as HTMLElement).style.transform = "translateY(-20px)";
				}
			});

			li.addEventListener("mouseleave", (e) => {
				let div = (document.querySelector(".divFigureSlide1") as HTMLElement);

				if (this.slider.id === "slide-immobilier" || this.slider.id === "slide-distribution") {
					(div.children[key].children[0] as HTMLElement).style.top = "90px";
				} else if (this.slider.id === "slide-finance") {
					(div.children[key] as HTMLElement).style.transform = "translateY(0)";
				}
			});
		});

		// hover des images pour surligner les li
		Array.from(document.querySelectorAll(".divFigureSlide1 figure")).forEach((figure, key) => {
			let li = (document.querySelector(".index") as HTMLElement).children[key] as HTMLElement;
			let div = li.children[0] as HTMLElement;

			(figure as HTMLElement).children[0].addEventListener("mouseover", (e) => {
				li.style.backgroundPosition = "left";
				div.style.backgroundPosition = "0 100%";
			});

			(figure as HTMLElement).children[0].addEventListener("mouseleave", (e) => {
				li.style.backgroundPosition = "right";
				div.style.backgroundPosition = "100%";
			});
		});

		if (document.getElementById("grab")) {
			let primaryMouseButtonDown = false;

			function setPrimaryButtonState(e: any) {
				const list = document.querySelector("#grab ul") as HTMLElement;
				const top = (document.getElementById("grab") as HTMLElement).getBoundingClientRect().top + 200;
				var flags = e.buttons !== undefined ? e.buttons : e.which;
				primaryMouseButtonDown = (flags & 1) === 1;

				if (primaryMouseButtonDown) {
					list.style.transform = `translateY(-${e.clientY - top}px)`;
				}
			}

			document.getElementById("grab")?.addEventListener("mousedown", setPrimaryButtonState);
			document.getElementById("grab")?.addEventListener("mousemove", setPrimaryButtonState);
			document.getElementById("grab")?.addEventListener("mouseup", setPrimaryButtonState);
		}

		// set slide au click des images
		let slideImmo = (document.querySelector("#slide-immobilier .slide1") as HTMLElement);
		let slideFinance = (document.querySelector("#slide-finance .slide1") as HTMLElement);
		let slideDistrib = (document.querySelector("#slide-distribution .slide1") as HTMLElement);

		if (slideImmo) {
			Array.from(slideImmo.children[1].children).forEach((figure, key) => {
				figure.children[0].addEventListener("click", () => {
					if (key === 2) {
						let getUrl = window.location;
						let baseUrl = getUrl.protocol + "//" + getUrl.host + "/";
						let urlLocal = baseUrl + getUrl.pathname.split('/')[1] + "/" + getUrl.pathname.split('/')[2] + "/" + "projet";

						if (getUrl.host === "localhost:88") {
							window.location.href = urlLocal;
						} else {
							window.location.href = baseUrl + '/projet';
						}
					} else {
						this.setIndex(key + 1);
						this.setSliderPos(this.currentSlide);
					}
				});
			});
		}
		if (slideFinance) {
			Array.from(slideFinance.children[1].children).forEach((figure, key) => {
				figure.addEventListener("click", () => {
					this.setIndex(key + 1);
					this.setSliderPos(this.currentSlide);
				});
			});
		}
		if (slideDistrib) {
			Array.from(slideDistrib.children[1].children).forEach((figure, key) => {
				figure.addEventListener("click", () => {
					this.setIndex(key + 1);
					this.setSliderPos(this.currentSlide);
				});
			});
		}

		// navigation, cursor fleche et click sur la partie gauche ou droite
		this.slider.addEventListener("mousemove", this.updateMousePos);
	}

	private updateMousePos = (e: MouseEvent) => {
		this.pageX = e.pageX;
		let middleWidth = window.innerWidth / 2;
		const circle = document.getElementById("circle") as HTMLElement;

		if (this.currentSlide > 0 && this.inView) {
			if (this.pageX > middleWidth) {
				if (this.currentSlide + 1 < this.slides.length) {
					this.slider.style.cursor = "none";
					circle.style.transform = "rotate(360deg) scale(1)";
				} else {
					this.slider.style.cursor = "default";
					circle.style.transform = "rotate(180deg) scale(0)";
				}
				circle.style.top = `${e.pageY - (circle.offsetHeight / 2)}px`;
				circle.style.left = `${e.pageX - (circle.offsetWidth / 2)}px`;
			} else {
				if (this.currentSlide > 0) {
					this.slider.style.cursor = "none";
					circle.style.transform = "rotate(180deg) scale(1)";
					circle.style.top = `${e.pageY - (circle.offsetHeight / 2)}px`;
					circle.style.left = `${e.pageX - (circle.offsetWidth / 2)}px`;
				}
			}
		} else {
			this.slider.style.cursor = "default";
			circle.style.transform = "rotate(180deg) scale(0)";
		}

		this.slider.removeEventListener("mousedown", this.clickMouse);
		this.slider.addEventListener("mousedown", this.clickMouse);

		// Pour le hover des listes dans les slides 
		Array.from(this.slider.querySelectorAll(".listOver")).forEach((element) => {
			let observer = new IntersectionObserver(([observables]) => {
				if (observables.isIntersecting) {
					if (
						e.pageY <= (element as HTMLElement).getBoundingClientRect().bottom &&
						e.pageY >= (element as HTMLElement).getBoundingClientRect().top &&
						e.pageX >= (element as HTMLElement).getBoundingClientRect().left &&
						e.pageX <= (element as HTMLElement).getBoundingClientRect().right
					) {
						if (this.isEnterMouse) {
							circle.style.zIndex = "0";
							this.isEnterMouse = false;
						}
						this.slider.removeEventListener("mousedown", this.clickMouse);
					} else {
						if (!this.isEnterMouse) {
							circle.style.zIndex = "2";
							this.isEnterMouse = true;
						}
					}
				}
			}, {
				threshold: [0]
			});
			observer.observe(element);
		});
	}

	private clickMouse = () => {
		let middleWidth = window.innerWidth / 2;

		if (this.currentSlide > 0 && this.inView) {
			if (this.pageX > middleWidth) {
				if (this.currentSlide + 1 < this.slides.length) {
					this.setSliderPos(this.currentSlide + 1);
					this.setIndex(this.currentSlide + 1);
				}
			} else {
				if (this.currentSlide > 0) {
					this.setSliderPos(this.currentSlide - 1);
					this.setIndex(this.currentSlide - 1);
				}
			}
		}
	}

	private onResize() {
		this.calcSlideWidth();
		this.setSliderPos(this.currentSlide, false);
	}

	private calcSlideWidth() {
		this.slideWidth = this.slides[0].clientWidth;
	}

	private updateSliderPos() {
		gsap.set(this.slidesWrapper, { x: this.sliderPos.x, lazy: true });
		this.setIndex(Math.round(-this.sliderPos.x / this.slideWidth));
		this.lastSliderPos.x = this.sliderPos.x;
	}

	private stopMomentum() {
		if (this.momentumTween) {
			this.momentumTween.kill();
			this.momentumTween = null;
		}
	}

	private setSliderPos(index: number, isAnimated = true) {
		this.stopMomentum();
		this.momentumTween = gsap.to(this.sliderPos, {
			duration: isAnimated ? this.duration : 0,
			x: -index * this.slideWidth,
			ease: 'power4',
			onUpdate: () => this.updateSliderPos(),
			onComplete: () => this.updateSliderPos(),
		});
	}

	private updateSliderPosLoop() {
		if (!this.dragging || !this.inView) return;
		this.updateSliderPos();
		const dist = this.pointerPos.x - this.lastPointerPos.x;
		this.lastPointerPos.x = this.pointerPos.x;
		this.totalDist += dist;
		this.sliderPos.x += dist;
		this.distLog.push(dist);
		while (this.distLog.length > 10) this.distLog.splice(0, 1);
		requestAnimationFrame(() => this.updateSliderPosLoop());
	}

	private onKeyDown(key: KeyboardEvent['key']) {
		clearInterval(this.interval);
		if (key === 'ArrowRight') this.next();
		else if (key === 'ArrowLeft') this.prev();
	}

	public prev = () => {
		if (this.currentSlide > 0 && this.inView) {
			this.setSliderPos(this.currentSlide - 1);
		}
	};

	public next = () => {
		if (this.currentSlide < this.slides.length - this.nbrPerSlide && this.inView) {
			this.setSliderPos(this.currentSlide + 1);
		}
	};

	private setIndex(index: number) {
		if (
			index === this.currentSlide ||
			index < 0 ||
			index > this.slides.length - this.nbrPerSlide
		) {
			return;
		}

		this.currentSlide = index;

		if (index === 0) {
			this.btnPrev.setAttribute('disabled', 'true');
		} else {
			this.btnPrev.removeAttribute('disabled');
		}

		if (index === this.slides.length - this.nbrPerSlide) {
			this.btnNext.setAttribute('disabled', 'true');
		} else {
			this.btnNext.removeAttribute('disabled');
		}

		if (this.currentSlide === 1) {
			if (document.getElementById("main-tolefi-finance")) {
				const para = document.querySelector("#slide-finance .slide2 .content2 p") as HTMLElement;

				if (para.children[para.children.length - 1].tagName !== "SPAN") (para).appendChild(document.createElement("span"));
				(document.querySelector("#slide-finance .slide2 figure") as HTMLElement).style.animation = "fadeParentFigureMetier 2s";
				(document.querySelector("#slide-finance .slide2 figure") as HTMLElement).style.opacity = "1";
				(document.querySelector("#slide-finance .slide2 figure img") as HTMLElement).style.animation = "fadeFigureMetier2 2.5s";
				(document.querySelector("#slide-finance .slide2 .content2") as HTMLElement).style.animation = "fadeParentFigureMetier 2.5s";
				(document.querySelector("#slide-finance .slide2 .content2 h2") as HTMLElement).style.animation = "fadeTitleMetier2 3.5s";
				(document.querySelector("#slide-finance .slide2 .content2 ul") as HTMLElement).style.animation = "fadeUlMetier2 3.5s";
				Array.from(document.querySelectorAll("#slide-finance .slide2 .content2 ul li span") as NodeListOf<HTMLElement>).forEach((e) => {
					e.style.animation = "fadeUlLiMetier2 4.5s";
				});
			} else if (document.getElementById("main-tolefi-immobilier")) {
				const para = document.querySelector("#slide-immobilier .slide2 .content2 .divparaPromotion") as HTMLElement;

				if (para.children[para.children.length - 1].tagName !== "SPAN") (para).appendChild(document.createElement("span"));
				(document.querySelector("#slide-immobilier .slide2 figure") as HTMLElement).style.animation = "fadeParentFigureMetier2 3s";
				(document.querySelector("#slide-immobilier .slide2 figure") as HTMLElement).style.opacity = "1";
				(document.querySelector("#slide-immobilier .slide2 figure img") as HTMLElement).style.animation = "fadeFigureMetier2 2.5s";
				(document.querySelector("#slide-immobilier .slide2 .content2") as HTMLElement).style.opacity = "1";
				(document.querySelector("#slide-immobilier .slide2 .content2") as HTMLElement).style.animation = "fadeParentFigureMetier 2.5s";
				(document.querySelector("#slide-immobilier .slide2 .content2 h2") as HTMLElement).style.animation = "fadeTitleMetier2 3.5s";
				(document.querySelector("#slide-immobilier .slide2 .content2 ul") as HTMLElement).style.animation = "fadeUlMetier2 3.5s";
				Array.from(document.querySelectorAll("#slide-immobilier .slide2 .content2 ul li span") as NodeListOf<HTMLElement>).forEach((e) => {
					e.style.animation = "fadeUlLiMetier2 4.5s";
				});
			} else if (document.getElementById("main-tolefi-distribution")) {
				const para = document.querySelector("#slide-distribution .slide2 .content2 .divparaDistribution") as HTMLElement;

				if (para.children[para.children.length - 1].tagName !== "SPAN") (para).appendChild(document.createElement("span"));
				(document.querySelector("#slide-distribution .slide2 figure") as HTMLElement).style.animation = "fadeParentFigureMetier2 3s";
				(document.querySelector("#slide-distribution .slide2 figure") as HTMLElement).style.opacity = "1";
				(document.querySelector("#slide-distribution .slide2 figure img") as HTMLElement).style.animation = "fadeFigureMetier22 1.5s";
				(document.querySelector("#slide-distribution .slide2 .content2") as HTMLElement).style.opacity = "1";
				(document.querySelector("#slide-distribution .slide2 .content2") as HTMLElement).style.animation = "fadeParentFigureMetier 2.5s";
				(document.querySelector("#slide-distribution .slide2 .content2 h2") as HTMLElement).style.animation = "fadeTitleMetier2 3.5s";
				(document.querySelector("#slide-distribution .slide2 .content2 ul") as HTMLElement).style.animation = "fadeUlMetier3 3.5s";
				Array.from(document.querySelectorAll("#slide-distribution .slide2 .content2 ul li span") as NodeListOf<HTMLElement>).forEach((e) => {
					e.style.animation = "fadeUlLiMetier2 4.5s";
				});
			}
		}
		else if (this.currentSlide === 2) {
			if (document.getElementById("main-tolefi-finance")) {
				const para = document.querySelector("#slide-finance .slide3 .content3 p") as HTMLElement;

				if (para.children[para.children.length - 1].tagName !== "SPAN") (para).appendChild(document.createElement("span"));
				(document.querySelector("#slide-finance .slide3 figure") as HTMLElement).style.animation = "fadeParentFigureMetier 2.5s";
				(document.querySelector("#slide-finance .slide3 figure") as HTMLElement).style.opacity = "1";
				(document.querySelector("#slide-finance .slide3 figure img") as HTMLElement).style.animation = "fadeFigureMetier2 2.5s";
				(document.querySelector("#slide-finance .slide3 .content3") as HTMLElement).style.animation = "fadeParentFigureMetier 2.5s";
				(document.querySelector("#slide-finance .slide3 .content3 h2") as HTMLElement).style.animation = "fadeTitleMetier2 3.5s";
				(document.querySelector("#slide-finance .slide3 .content3 ul") as HTMLElement).style.animation = "fadeUlMetier3 3.5s";
				Array.from(document.querySelectorAll("#slide-finance .slide3 .content3 ul li span") as NodeListOf<HTMLElement>).forEach((e) => {
					e.style.animation = "fadeUlLiMetier2 4.5s";
				});
			} else if (document.getElementById("main-tolefi-immobilier")) {
				const para = document.querySelector("#slide-immobilier .slide3 .content3 p") as HTMLElement;

				if (para.children[para.children.length - 1].tagName !== "SPAN") (para).appendChild(document.createElement("span"));
				(document.querySelector("#slide-immobilier .slide3 .content3") as HTMLElement).style.animation = "fadeParentFigureMetier 2.5s";
				(document.querySelector("#slide-immobilier .slide3 .content3") as HTMLElement).style.opacity = "1";
				(document.querySelector("#slide-immobilier .slide3 .content3 h2") as HTMLElement).style.animation = "fadeTitleMetier2 3.5s";
				(document.querySelector("#slide-immobilier .slide3 .content3 ul") as HTMLElement).style.animation = "fadeUlMetier3 3.5s";
				Array.from(document.querySelectorAll("#slide-immobilier .slide3 .content3 ul li span") as NodeListOf<HTMLElement>).forEach((e) => {
					e.style.animation = "fadeUlLiMetier2 4.5s";
				});
				Array.from(document.querySelectorAll("#slide-immobilier .slide3 .listFonciere li") as NodeListOf<HTMLElement>).forEach((e) => {
					e.style.animation = "fadeUlLiCardMetier 2.5s";
				});
			} else if (document.getElementById("main-tolefi-distribution")) {
				const para = document.querySelector("#slide-distribution .slide3 .content2 .divparaDistribution2") as HTMLElement;

				if (para.children[para.children.length - 1].tagName !== "SPAN") (para).appendChild(document.createElement("span"));
				(document.querySelector("#slide-distribution .slide3 figure") as HTMLElement).style.animation = "fadeParentFigureMetier 2s";
				(document.querySelector("#slide-distribution .slide3 figure") as HTMLElement).style.opacity = "1";
				(document.querySelector("#slide-distribution .slide3 figure img") as HTMLElement).style.animation = "fadeFigureMetier3 2.5s";
				(document.querySelector("#slide-distribution .slide3 .content2") as HTMLElement).style.animation = "fadeParentFigureMetier 2.5s";
				(document.querySelector("#slide-distribution .slide3 .content2 h2") as HTMLElement).style.animation = "fadeTitleMetier2 3.5s";
				(document.querySelector("#slide-distribution .slide3 .content2 ul") as HTMLElement).style.animation = "fadeUlMetier2 3.5s";
				Array.from(document.querySelectorAll("#slide-distribution .slide3 .content2 ul li span") as NodeListOf<HTMLElement>).forEach((e) => {
					e.style.animation = "fadeUlLiMetier2 4.5s";
				});
			}
		}
		else if (this.currentSlide === 3) {
			if (document.getElementById("main-tolefi-distribution")) {
				const para = document.querySelector("#slide-distribution .slide4 .content2 p") as HTMLElement;

				if (para.children[para.children.length - 1].tagName !== "SPAN") (para).appendChild(document.createElement("span"));
				(document.querySelector("#slide-distribution .slide4 figure") as HTMLElement).style.animation = "fadeParentFigureMetier3 3s";
				(document.querySelector("#slide-distribution .slide4 figure") as HTMLElement).style.opacity = "1";
				(document.querySelector("#slide-distribution .slide4 figure img") as HTMLElement).style.animation = "fadeFigureMetier2 3s";
				(document.querySelector("#slide-distribution .slide4 .content2") as HTMLElement).style.opacity = "1";
				(document.querySelector("#slide-distribution .slide4 .content2") as HTMLElement).style.animation = "fadeParentFigureMetier 2.5s";
				(document.querySelector("#slide-distribution .slide4 .content2 h2") as HTMLElement).style.animation = "fadeTitleMetier2 3.5s";
				(document.querySelector("#slide-distribution .slide4 .content2 ul") as HTMLElement).style.animation = "fadeUlMetier3 3.5s";
				Array.from(document.querySelectorAll("#slide-distribution .slide4 .content2 ul li span") as NodeListOf<HTMLElement>).forEach((e) => {
					e.style.animation = "fadeUlLiMetier2 4.5s";
				});
			}
		}
	}
}
