import { gsap } from 'gsap';

function animationContact() {
    var tlContact = gsap.timeline();
    var contact = document.getElementById('contant');
    var form = document.querySelectorAll('#contant form');
    var h2 = document.querySelectorAll('#contant h2');
    var width;
    var height;

    if (window.innerWidth >= 1600) {
        width = "60%";
        height = "70%";
    } else if (window.innerWidth >= 824) {
        width = "75%";
        height = "80%";
    } else if (window.innerWidth >= 508) {
        width = "95%";
        height = "55%";
    } else {
        width = "100%";
        height = "88%";
    }

    tlContact.fromTo(contact, { width: "0%" }, { width: width, duration: 1 })
        .fromTo(contact, { height: "5%" }, { height: height, duration: 1 })
        .fromTo(form, { duration: 1, opacity: 0, }, { duration: 1, opacity: 1, })
        .fromTo(h2, { duration: 1, opacity: 0, }, { duration: 1, opacity: 1, }, "<");
}

export default animationContact;
