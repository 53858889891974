import { gsap, TweenLite, TweenMax } from 'gsap';

interface SliderActusProps {
	slider: HTMLElement;
	btnPrev: HTMLElement;
	btnNext: HTMLElement;
}

export class SliderActus {
	dragging = false;
	pointerPos = { x: 0 };
	lastPointerPos = { x: 0 };
	sliderPos = { x: 0 };
	lastSliderPos = { x: 0 };
	totalDist = 0;
	currentSlide = 0;
	momentumTween: TweenLite | TweenMax | null = null;
	distLog: number[] = [];
	velocity = 10;
	duration = 0.8;
	slideWidth = 0;
	inView = true;
	nbrPerSlide = (window.matchMedia("(max-width: 767px)").matches ? 1 : ((window.matchMedia("(max-width: 1025px)").matches) ? 2 : 3));
	y = 0;
	slidingVertically = false; // au cas où on scroll au touch
	observer: IntersectionObserver;
	interval?: number;
	slider: HTMLElement;
	btnPrev: HTMLElement;
	btnNext: HTMLElement;
	slidesWrapper: HTMLUListElement;
	slides: NodeListOf<HTMLLIElement>;
	links: NodeListOf<HTMLAnchorElement>;

	constructor({ btnNext, btnPrev, slider }: SliderActusProps) {
		this.slider = slider;
		this.btnPrev = btnPrev;
		this.btnNext = btnNext;
		this.slidesWrapper = this.slider.querySelector('ul')!;
		this.slides = this.slidesWrapper.querySelectorAll(':scope > li');
		this.links = this.slidesWrapper.querySelectorAll('a');

		this.bind();
		this.onResize();

		this.observer = new IntersectionObserver(
			([entry]) => (this.inView = entry.isIntersecting),
			{ threshold: 0 },
		);
		this.observer.observe(this.slider);
	}

	private bind() {
		this.btnPrev.addEventListener('click', this.prev);
		this.btnNext.addEventListener('click', this.next);

		this.slider.addEventListener('mousedown', (e) =>
			this.onPointerDown(e.pageX),
		);

		this.slider.addEventListener('touchstart', (e) => {
			this.slidingVertically = false;
			this.y = e.touches[0].pageY;
			this.onPointerDown(e.touches[0].pageX);
		});

		this.links.forEach((link) => {
			link.addEventListener('click', (e) => {
				if (this.totalDist !== 0) e.preventDefault();
			});
		});

		window.addEventListener('mousemove', (e) => this.onPointerMove(e.pageX));
		window.addEventListener('touchmove', (e) => {
			const diffY = Math.abs(this.y - e.touches[0].pageY);
			if (diffY > 20 && Math.abs(this.totalDist) < 20)
				this.slidingVertically = true;
			if (!this.slidingVertically) this.onPointerMove(e.touches[0].pageX);
			this.y = e.touches[0].pageY;
		});
		window.addEventListener('mouseup', () => this.onPointerUp());
		window.addEventListener('touchend', () => this.onPointerUp());
		window.addEventListener('keydown', (e) => this.onKeyDown(e.key));
		window.addEventListener('resize', () => this.onResize());
	}

	private onResize() {
		this.calcSlideWidth();
		this.setSliderPos(this.currentSlide, false);
		const w = window.innerWidth;
	}

	private calcSlideWidth() {
		this.slideWidth = this.slides[0].clientWidth;
	}

	private onPointerDown(x: number) {
		clearInterval(this.interval);
		this.dragging = true;
		this.pointerPos = { x };
		this.lastPointerPos = { x };
		this.totalDist = 0;
		this.distLog = [];
		this.stopMomentum();
		this.updateSliderPosLoop();
	}

	private onPointerMove(x: number) {
		if (this.dragging) this.pointerPos = { x };
	}

	private onPointerUp() {
		if (!this.dragging) return;

		this.dragging = false;

		const releaseSpeed =
			this.distLog.reduce((a, b) => a + b, 0) / (this.distLog.length || 1);

		let targetX = this.sliderPos.x + releaseSpeed * this.velocity;
		targetX = Math.round(targetX / this.slideWidth) * this.slideWidth;
		let targetSlide = -targetX / this.slideWidth;
		let excess = 0;

		if (targetSlide < 0) {
			excess = targetSlide;
			targetSlide = 0;
		} else if (targetSlide >= this.slides.length - this.nbrPerSlide) {
			excess = targetSlide - (this.slides.length - this.nbrPerSlide);
			targetSlide = this.slides.length - this.nbrPerSlide;
		}
		if (excess !== 0) targetX = -targetSlide * this.slideWidth;

		this.momentumTween = gsap.to(this.sliderPos, {
			duration: this.duration - Math.abs(excess) / 20,
			x: targetX,
			ease: 'power2',
			onUpdate: () => this.updateSliderPos(),
			onComplete: () => this.updateSliderPos(),
		});
	}

	private updateSliderPos() {
		// if (window.matchMedia("(min-width: 768px)").matches) {
		gsap.set(this.slidesWrapper, { x: this.sliderPos.x, lazy: true });
		this.lastSliderPos.x = this.sliderPos.x;
		this.setIndex(Math.round(-this.sliderPos.x / this.slideWidth));

		// } else {
		// 	// gsap.set(this.slidesWrapper, { x: this.sliderPos.x, lazy: true });
		// 	// gsap.set(this.slides[this.currentSlide], { rotate: -50, lazy: true });
		// 	// this.slides[this.currentSlide].classList.add("actif");

		// 	let actif = document.querySelector(".actif") as HTMLElement;
		// 	let nbr = this.sliderPos.x + (this.slideWidth * this.currentSlide);

		// 	gsap.set(actif, { x: (nbr * 1.5), y: (-nbr / 5), rotate: (nbr / 8), lazy: true });

		// 	// if (this.test > this.pointerPos.x) {
		// 	// 	// console.log("left", this.test);

		// 	// 	this.test = this.pointerPos.x;
		// 	// } else {
		// 	// 	// console.log("right", this.test);

		// 	// 	this.test = this.pointerPos.x;
		// 	// }

		// 	if (Number.isInteger(-this.sliderPos.x / this.slideWidth)) {
		// 		// console.log((-this.sliderPos.x / this.slideWidth));
		// 		this.setIndex(Math.round(-this.sliderPos.x / this.slideWidth));
		// 	}
		// this.lastSliderPos.x = this.sliderPos.x;
		// }
	}

	private stopMomentum() {
		if (this.momentumTween) {
			this.momentumTween.kill();
			this.momentumTween = null;
		}
	}

	private setSliderPos(index: number, isAnimated = true) {
		this.stopMomentum();
		this.momentumTween = gsap.to(this.sliderPos, {
			duration: isAnimated ? this.duration : 0,
			x: -index * this.slideWidth,
			ease: 'power4',
			onUpdate: () => this.updateSliderPos(),
			onComplete: () => this.updateSliderPos(),
		});
	}

	private updateSliderPosLoop() {
		if (!this.dragging || !this.inView) return;
		this.updateSliderPos();
		const dist = this.pointerPos.x - this.lastPointerPos.x;
		this.lastPointerPos.x = this.pointerPos.x;
		this.totalDist += dist;
		this.sliderPos.x += dist;
		this.distLog.push(dist);
		while (this.distLog.length > 10) this.distLog.splice(0, 1);
		requestAnimationFrame(() => this.updateSliderPosLoop());
	}

	private onKeyDown(key: KeyboardEvent['key']) {
		clearInterval(this.interval);
		if (key === 'ArrowRight') this.next();
		else if (key === 'ArrowLeft') this.prev();
	}

	public prev = () => {
		if (this.currentSlide > 0 && this.inView) {
			this.setSliderPos(this.currentSlide - 1);
		}
	};

	public next = () => {
		if (this.currentSlide < this.slides.length - this.nbrPerSlide && this.inView) {
			this.setSliderPos(this.currentSlide + 1);
		}
	};

	private setIndex(index: number) {
		if (
			index === this.currentSlide ||
			index < 0 ||
			index > this.slides.length - this.nbrPerSlide
		) {
			// if (window.matchMedia("(max-width: 767px)").matches) {
			// 	this.slides[this.currentSlide].classList.add("actif");
			// 	this.slides[this.currentSlide].classList.remove("inActif");

			// 	for (let i = 0; i < this.slides.length; i++) {
			// 		const element = this.slides[i];

			// 		if (this.currentSlide !== i) {
			// 			element.classList.add("inActif");
			// 			element.classList.remove("actif");
			// 		}
			// 	}
			// }

			return;
		}

		this.currentSlide = index;

		if (index === 0) {
			this.btnPrev.setAttribute('disabled', 'true');
		} else {
			this.btnPrev.removeAttribute('disabled');
		}

		if (index === this.slides.length - this.nbrPerSlide) {
			this.btnNext.setAttribute('disabled', 'true');
		} else {
			this.btnNext.removeAttribute('disabled');
		}

		// if (window.matchMedia("(max-width: 767px)").matches) {
		// 	this.slides[this.currentSlide].classList.add("actif");
		// 	this.slides[this.currentSlide].classList.remove("inActif");

		// 	for (let i = 0; i < this.slides.length; i++) {
		// 		const element = this.slides[i];

		// 		if (this.currentSlide !== i) {
		// 			element.classList.add("inActif");
		// 			element.classList.remove("actif");
		// 		}
		// 	}
		// }
	}
}
