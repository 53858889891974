interface AjaxSearchProps {
    inputValue: string;
}

export class AjaxSearch {
    inputValue: string;

    constructor({ inputValue }: AjaxSearchProps) {
        this.inputValue = inputValue;

        this.bind();
    }

    private bind() {
        this.goTo(this.inputValue);
    }

    async goTo(inputValue: string) {
        let url = location.hostname === "localhost" ? "/html/tolefi/searchBar" : "/searchBar/";

        try {
            const req = await fetch(`${url + '?r=' + inputValue}&json=true`);
            const html = await req.text();

            let listeSearch = document.getElementById('listeSearch') as HTMLUListElement;
            listeSearch.innerHTML = html;

            if (inputValue === "") {
                listeSearch.style.visibility = "hidden";
                listeSearch.style.overflow = "hidden";
            } else {
                listeSearch.style.overflow = "auto";
                listeSearch.style.visibility = "visible";
            }

        } catch (error) {
            console.log(error);
        }
    }
}
