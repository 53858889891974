import { organigrammeOnDesktop } from './organigrammeDesktop'
import { organigrammeOnMobile } from './organigrammeMobile'

const organigrammeContainer: HTMLElement | any = document.querySelector(
    '#organigramme',
)
const organigrammeDesktop: HTMLElement | any = document.querySelector(
    '#organigrammeDesktop',
)

export const animeOnEvent = () => {
    let counter = 0;
    // let element = window.matchMedia("(max-width: 768px)").matches ? document : (document.getElementById('home') as HTMLElement);

    (document.getElementById('home') as HTMLElement).addEventListener('scroll', () => {
        const { scrollTop } = document.documentElement
        const view: any = organigrammeContainer?.getBoundingClientRect().top;
        const viewDesktop: any = organigrammeDesktop?.getBoundingClientRect().top;

        if (window.matchMedia("(max-width: 508px)").matches) {
            if (scrollTop > scrollTop + view - 200) {
                organigrammeContainer.style.opacity = 1;

                if (counter < 1) {
                    organigrammeOnMobile();
                    counter++;
                }
            }
        } else {
            if (scrollTop > scrollTop + viewDesktop - 300) {
                organigrammeDesktop.style.opacity = 1;

                if (counter < 1) {
                    organigrammeOnDesktop();
                    counter++;
                }
            }
        }
    });
}
