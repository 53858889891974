const borderTolefiFinance: HTMLElement | null = document.querySelector('.border-tolefi-finance');
const borderImmobilierDistribution: HTMLElement | null = document.querySelector('.border-immobilier-distribution');
const borderAboveImmobilier: HTMLElement | null = document.querySelector('.borderAboveImmobilier');
const borderAboveDistribution: HTMLElement | null = document.querySelector('.borderAboveDistribution');
const immobilierTitle: any | null = document.querySelector('.immobilierTitle');
const distributionTitle: any | null = document.querySelector('.distributionTitle');
const financeTitle: any | null = document.querySelector('.financeTitle');
const littleBorderTopBelowImmobilier: any | null = document.querySelector('.littleBorderTopBelowImmobilier');
const borderBelowFinance: any | null = document.querySelector('.borderBelowFinance');
const borderBelowDistribution: any | null = document.querySelector('.borderBelowDistribution');
const borderBelowImmobilier: any | null = document.querySelector('.borderBelowImmobilier');
const littleBorderRightBelowImmobilier: any | null = document.querySelector('.littleBorderRightBelowImmobilier');
const littleBorderBottomBelowImmobilier: any | null = document.querySelector('.littleBorderBottomBelowImmobilier');
const promotionBorder: any | null = document.querySelector('.promotionBorder');
const promotionTitle: any | null = document.querySelector('.promotionTitle');
const fonciereBorder: any | null = document.querySelector('.fonciereBorder');
const fonciereTitle: any | null = document.querySelector('.fonciereTitle');
const littleBorderLeftCbo: any | null = document.querySelector('.littleBorderLeftCbo');
const cboBorder: any | null = document.querySelector('.cboBorder');
const cboTitle: any | null = document.querySelector('.cboTitle');
const littleBorderRightChauffage: any | null = document.querySelector('.littleBorderRightChauffage');
const chauffageBorder: any | null = document.querySelector('.chauffageBorder');
const chauffageTitle: any | null = document.querySelector('.chauffageTitle');
const borderBelowPromotion: any | null = document.querySelector('.borderBelowPromotion');
const borderLeftResidentiel: any | null = document.querySelector('.borderLeftResidentiel');
const residentielOneBorder: any | null = document.querySelector('.residentielOneBorder');
const residentielOneTitle: any | null = document.querySelector('.residentielOneTitle');
const borderRightResidentiel: any | null = document.querySelector('.borderRightResidentiel');
const residentielTwoBorder: any | null = document.querySelector('.residentielTwoBorder');
const residentielTwoTitle: any | null = document.querySelector('.residentielTwoTitle');
const borderLeftBureau: any | null = document.querySelector('.borderLeftBureau');
const bureauOneBorder: any | null = document.querySelector('.bureauOneBorder');
const bureauOneTitle: any | null = document.querySelector('.bureauOneTitle');
const borderRightBureau: any | null = document.querySelector('.borderRightBureau');
const bureauTwoBorder: any | null = document.querySelector('.bureauTwoBorder');
const bureauTwoTitle: any | null = document.querySelector('.bureauTwoTitle');
const borderLeftLogistique: any | null = document.querySelector('.borderLeftLogistique');
const logistiqueOneBorder: any | null = document.querySelector('.logistiqueOneBorder');
const logistiqueOneTitle: any | null = document.querySelector('.logistiqueOneTitle');
const borderRightLogistique: any | null = document.querySelector('.borderRightLogistique');
const logistiqueTwoBorder: any | null = document.querySelector('.logistiqueTwoBorder');
const logistiqueTwoTitle: any | null = document.querySelector('.logistiqueTwoTitle');
const borderRightRetail: any | null = document.querySelector('.borderRightRetail');
const retailBorder: any | null = document.querySelector('.retailBorder');
const retailTitle: any | null = document.querySelector('.retailTitle');
const borderLeftCelyad: any | null = document.querySelector('.borderLeftCelyad');
const celyadBorder: any | null = document.querySelector('.celyadBorder');
const celyadTitle: any | null = document.querySelector('.celyadTitle');
const borderLeftParticipationsDiverses: any | null = document.querySelector('.borderLeftParticipationsDiverses');
const participationsDiversesBorder: any | null = document.querySelector('.participationsDiversesBorder');
const participationsDiversesTitle: any | null = document.querySelector('.participationsDiversesTitle');
const borderRightCharbonDeBois: any | null = document.querySelector('.borderRightCharbonDeBois');
const charbonDeBoisBorder: any | null = document.querySelector('.charbonDeBoisBorder');
const charbonDeBoisTitle: any | null = document.querySelector('.charbonDeBoisTitle');
const borderRightParaffine: any | null = document.querySelector('.borderRightParaffine');
const paraffineBorder: any | null = document.querySelector('.paraffineBorder');
const paraffineTitle: any | null = document.querySelector('.paraffineTitle');
const borderRightTraitementDeLair: any | null = document.querySelector('.borderRightTraitementDeLair');
const traitementDeLairBorder: any | null = document.querySelector('.traitementDeLairBorder');
const traitementDeLairTitle: any | null = document.querySelector('.traitementDeLairTitle');
const borderRightBoisDeChauffe: any | null = document.querySelector('.borderRightBoisDeChauffe');
const boisDeChauffeBorder: any | null = document.querySelector('.boisDeChauffeBorder');
const boisDeChauffeTitle: any | null = document.querySelector('.boisDeChauffeTitle');
const borderBelowFonciere: any | null = document.querySelector('.borderBelowFonciere');

const scalingElement = (element: any, timeline: any, duration?: string) => {
	timeline.fromTo(element, { scale: 0 }, { scale: 1 }, duration)
}

const translateAndOpacityElement = (element: any, timeline: any, translateYStart: string, opacityStart: number, opacityEnd: number, translateYEnd: string, duration?: string) => {
	timeline.fromTo(element, { opacity: opacityStart, translateY: translateYStart }, { opacity: opacityEnd, translateY: translateYEnd }, duration)
}

const animateBorderSvg = (element: any, tl: any, timing?: string) => {
	tl.fromTo(element, { stroke: '#FFFFFF', strokeDasharray: 1000, strokeDashoffset: 1000 }, { strokeDashoffset: 0, duration: 0.5 }, timing)
}

const animateOpacityElement = (tl: any, element: any, timing?: string) => {
	tl.fromTo(element, { opacity: 0 }, { opacity: 1 }, timing)
}

const animateBorderRight = (tl: any, element: any, timing?: string) => {
	tl.fromTo(element, { scale: 0, transformOrigin: "100% 0%" }, { scale: 1 }, timing)
}

const scaleAndTransformElement = (tl: any, element: any, duration?: string) => {
	tl.fromTo(element, { scale: 0, transformOrigin: '100% 0%' }, { scale: 1 }, duration)
}

export const organigrammeOnDesktop = () => {
	const tl = gsap.timeline({ duration: 0.05 });
	scalingElement(borderTolefiFinance, tl)
	tl.fromTo(borderImmobilierDistribution, { scale: 0, transformOrigin: '50% 0%' }, { scale: 1 })
	scalingElement(borderAboveImmobilier, tl)
	scalingElement(borderAboveDistribution, tl, "<")
	translateAndOpacityElement(immobilierTitle, tl, "-50px", 0, 1, "0px", "-=0.5")
	translateAndOpacityElement(financeTitle, tl, "-50px", 0, 1, "0px", "-=0.5")
	scalingElement(borderBelowFinance, tl, "+=0.5")
	translateAndOpacityElement(distributionTitle, tl, "-50px", 0, 1, "0px", "-=1.5")

	scalingElement(borderBelowDistribution, tl, "-=0.5")
	scalingElement(littleBorderTopBelowImmobilier, tl, "-=0.5")
	scalingElement(borderBelowImmobilier, tl, "-=0.2")
	scalingElement(littleBorderBottomBelowImmobilier, tl, "-=0.2")
	animateBorderSvg(promotionBorder, tl)
	animateOpacityElement(tl, promotionTitle, "-=0.1")
	scalingElement(littleBorderRightBelowImmobilier, tl, "-=1")
	animateBorderSvg(fonciereBorder, tl, "-=1")
	animateOpacityElement(tl, fonciereTitle, "-=0.5")

	/* SIDE FINANCE */
	// TAG CBO
	scalingElement(littleBorderLeftCbo, tl, "-=1.5")
	animateBorderSvg(cboBorder, tl, "-=0.8")
	animateOpacityElement(tl, cboTitle, "-=0.8")

	// TAG CELYAD
	scalingElement(borderLeftCelyad, tl, "-=0.5")
	animateBorderSvg(celyadBorder, tl, "-=0.5")
	animateOpacityElement(tl, celyadTitle, "-=0.5")

	// // TAG PARTICIPATIONS DIVERSES
	scalingElement(borderLeftParticipationsDiverses, tl, "-=0.2")
	animateBorderSvg(participationsDiversesBorder, tl, "-=0.2")
	animateOpacityElement(tl, participationsDiversesTitle, "-=0.2")

	/* SIDE DISTRIBUTION */
	// TAG CHAUFFAGE
	animateBorderRight(tl, littleBorderRightChauffage, "-=2.5")
	animateBorderSvg(chauffageBorder, tl, "-=2")
	animateOpacityElement(tl, chauffageTitle, "-=2")

	// TAG CHARBON DE BOIS
	animateBorderRight(tl, borderRightCharbonDeBois, "-=0.8")
	animateBorderSvg(charbonDeBoisBorder, tl, "-=0.8")
	animateOpacityElement(tl, charbonDeBoisTitle, "-=0.8")

	// TAG PARAFFINE
	animateBorderRight(tl, borderRightParaffine, "-=1")
	animateBorderSvg(paraffineBorder, tl, "-=1")
	animateOpacityElement(tl, paraffineTitle, "-=1")

	// TAG TRAITEMENT DE L'AIR
	animateBorderRight(tl, borderRightTraitementDeLair, "-=0.2")
	animateBorderSvg(traitementDeLairBorder, tl, "-=0.2")
	animateOpacityElement(tl, traitementDeLairTitle, "-=0.2")

	// TAG BOIS DE CHAUFFE
	animateBorderRight(tl, borderRightBoisDeChauffe, "-=0.5")
	animateBorderSvg(boisDeChauffeBorder, tl, "-=0.5")
	animateOpacityElement(tl, boisDeChauffeTitle, "-=0.5")

	/* SIDE PROMOTION */
	// BORDER BELOW PROMOTION
	scalingElement(borderBelowPromotion, tl, "-=2.5")
	// TAG RESIDENTIEL ONE
	scalingElement(borderLeftResidentiel, tl, "-=2")
	animateBorderSvg(residentielOneBorder, tl, "-=2")
	animateOpacityElement(tl, residentielOneTitle, "-=2")
	// TAG BUREAU ONE
	scalingElement(borderLeftBureau, tl, "-=1.6")
	animateBorderSvg(bureauOneBorder, tl, "-=1.6")
	animateOpacityElement(tl, bureauOneTitle, "-=1.6")
	// TAG LOGISTIQUE ONE
	scalingElement(borderLeftLogistique, tl, "-=1")
	animateBorderSvg(logistiqueOneBorder, tl, "-=0.5")
	animateOpacityElement(tl, logistiqueOneTitle, "-=0.5")

	/* SIDE FONCIERE */
	scalingElement(borderBelowFonciere, tl, "-=2.5")
	// TAG RESIDENTIEL TWO
	scaleAndTransformElement(tl, borderRightResidentiel, "-=2.5")
	animateBorderSvg(residentielTwoBorder, tl, "-=2.5")
	animateOpacityElement(tl, residentielTwoTitle, "-=2.5")

	// TAG BUREAU TWO
	scaleAndTransformElement(tl, borderRightBureau, "-=2")
	animateBorderSvg(bureauTwoBorder, tl, "-=1.5")
	animateOpacityElement(tl, bureauTwoTitle, "-=1.5")
	// TAG LOGISTIQUE TWO
	scaleAndTransformElement(tl, borderRightLogistique, "-=1.5")
	animateBorderSvg(logistiqueTwoBorder, tl, "-=1")
	animateOpacityElement(tl, logistiqueTwoTitle, "-=1")
	// TAG RETAIL
	scaleAndTransformElement(tl, borderRightRetail, "-=1")
	animateBorderSvg(retailBorder, tl, "-=0.5")
	animateOpacityElement(tl, retailTitle, "-=0.5")
}
