interface AnimGroupeProps {
	section: HTMLElement;
}

export class AnimGroupe {
	section: HTMLElement;
	contentPrimary: HTMLElement;
	background: HTMLElement;
	primaryPara: HTMLElement;
	list: NodeListOf<HTMLLIElement>;
	secondaryPara: NodeListOf<HTMLElement>;
	secondaryH3: HTMLElement;
	cta: HTMLElement;

	constructor({ section }: AnimGroupeProps) {
		this.section = section;
		this.contentPrimary = document.querySelector("#groupe .contentPrimary") as HTMLElement;
		this.primaryPara = document.querySelector("#groupe .contentPrimary .primary p") as HTMLElement;
		this.background = document.querySelector("#groupe figure") as HTMLElement;
		this.list = document.querySelectorAll("#groupe .contentPrimary .primary > ul li") as NodeListOf<HTMLLIElement>;
		this.secondaryPara = document.querySelectorAll("#groupe .contentPrimary .secondary div p") as NodeListOf<HTMLElement>;
		this.secondaryH3 = document.querySelector("#groupe .contentPrimary .secondary h3") as HTMLElement;
		this.cta = document.getElementById('btn-home-groupe') as HTMLElement;

		this.bind();
		this.nextContent();
	}

	private bind() {
		let counter = 0;

		const { scrollTop, clientHeight } = document.documentElement;
		const view: any = this.section?.getBoundingClientRect().top;
		const scrollTopAndView = scrollTop + view;
		// let element = window.matchMedia("(max-width: 768px)").matches ? document : (document.getElementById('home') as HTMLElement);

		if (scrollTop > (scrollTopAndView - 500)) {
			this.appear();
		} else {
			(document.getElementById('home') as HTMLElement).addEventListener('scroll', () => {
				const view: any = this.section?.getBoundingClientRect().top;
				const scrollTopAndView = scrollTop + view;

				if (scrollTop > (scrollTopAndView - 500)) {
					if (counter < 1) {
						setTimeout(() => {
							this.appear();
						}, 750);

						counter++;
					}
				}
			});
		}
	}

	private appear() {
		if (window.matchMedia("(max-width: 768px)").matches) {
			this.contentPrimary.style.transform = "translateX(0%)";
		} else if (window.matchMedia("(max-width: 1025px)").matches) {
			this.contentPrimary.style.transform = "translateX(50%)";
		} else {
			this.contentPrimary.style.transform = "translateX(60%)";
			(document.querySelector("#groupe > .cta") as HTMLElement).style.transform = "translateY(0)";
		}
		this.background.style.transform = "translateX(-15%)";
	}

	private nextContent() {
		let isOpen = false;

		this.cta.addEventListener("click", () => {
			if (!isOpen) {
				this.contentPrimary.style.transform = "translateX(0%)";
				this.primaryPara.style.transform = "translateX(-110%)";
				(this.cta.children[0].children[0] as HTMLElement).style.transform = "translateX(-100%)";
				(this.cta.children[0] as HTMLElement).style.width = "20px";
				(this.cta.children[1] as HTMLElement).style.transform = "rotate(226deg)";
				(this.cta.children[1] as HTMLElement).style.right = "42%";
				this.cta.style.padding = "30px 40px";
				this.cta.style.borderRadius = "50%";
				(this.cta.children[0].children[0] as HTMLElement).classList.add("open");
				(this.cta.children[0].children[1] as HTMLElement).classList.add("open");

				if (window.matchMedia("(max-width: 2000px)").matches) {
					this.cta.style.marginTop = "-50px";
				}

				for (let i = 0; i < this.list.length; i++) {
					setTimeout(() => {
						this.list[i].style.transform = "translateY(0%)";
					}, 800 + (50 * i));
				}

				setTimeout(() => {
					this.secondaryH3.classList.add("classBefore");
					this.secondaryPara[0].style.transform = "translateX(0%)";
					this.secondaryPara[1].style.transform = "translateX(0%)";
				}, 1000);

				(document.querySelector("#groupe > .cta") as HTMLElement).style.transform = "translateY(150px)";

				isOpen = true;
			} else {
				this.contentPrimary.style.transform = "translateX(60%)";
				this.primaryPara.style.transform = "translateX(0%)";
				(this.cta.children[0].children[0] as HTMLElement).style.transform = "translateX(0%)";
				(this.cta.children[0] as HTMLElement).style.width = "130px";
				(this.cta.children[1] as HTMLElement).style.transform = "rotate(0deg)";
				(this.cta.children[1] as HTMLElement).style.right = "20px";
				this.cta.style.padding = "0px 15px";
				this.cta.style.borderRadius = "25px";
				this.cta.style.marginTop = "0px";
				(this.cta.children[0].children[0] as HTMLElement).classList.remove("open");
				(this.cta.children[0].children[1] as HTMLElement).classList.remove("open");

				for (let i = 0; i < this.list.length; i++) {
					setTimeout(() => {
						this.list[i].style.transform = "translateY(-100%)";
					}, (50 * i));
				}

				this.secondaryH3.classList.remove("classBefore");
				this.secondaryPara[0].style.transform = "translateX(100%)";
				this.secondaryPara[1].style.transform = "translateX(100%)";
				(document.querySelector("#groupe > .cta") as HTMLElement).style.transform = "translateY(0)";

				isOpen = false;
			}
		});
	}
}
